/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  CircularProgress,
  makeStyles,
  TableCell,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiLoadReportVariantData } from "src/api";
import safeLogger from "src/services/safeLogger";
import VariantTable from "./table";
import { useVirtual } from "react-virtual";
import { useInfiniteQuery } from "@tanstack/react-query";
import { reorder } from "src/components/pages/settings/tableConfigPage/tableLayoutEditor";
import { connect, useDispatch } from "react-redux";
import { Skeleton } from "@material-ui/lab";
import { ArrowDropUp } from "@material-ui/icons";
import { updateFilter } from "src/redux/actions";
import { useSelector } from "react-redux";
import { queryClient } from "src/App";
import { getTranslated } from "../utils/helpers";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    width: 100,
    maxHeight: 40,
  },
}));

const NewReportVirtualizedTableConnected = React.memo(
  function NewReportVirtualizedTableConnected({
    setDetail1Func,
    setDetail2Func,
    setDetail4Func,
    setDetail6Func,
    tableColDetails,
    appConf,
    tableSortState,
    setTableSortState,
    tableFilterState,
    identifierColumn,
    visibleCols,
    setTableFilterState,
    setColDetails,
    setIsCalculated,
    virtualTableHeight,
    setRowAnnotations,
    setActiveRowId,
    userObject,
    setDetail3Func,
    advanceFilter,
    setCheckedRows,
    checkedRows,
    setSpecifiedValues,
    setVisibleCols,
    setTotalRowCount,
    defaultColDetailsObject,
    setIgvLocation,
    colDetails,
    setDetailLinks,
    hpo,
    reportId,
    setRsID,
    setUniprot,
    setRowData,
    activeRowId,
    isOrable,
    reportMeta,
    tableFilterTypes,
  }) {
    const tableHelper = createColumnHelper();
    const [columns, setColumns] = useState([]);
    const [isFetchingFirstTime, setIsFetchingFirstTime] = useState(true);
    const [page, setPage] = useState(0);
    const [dynamicRowCount, setDynamicRowCount] = useState(0);
    const [selectedRow, setSelectedRow] = useState(null);
    // const [loading, setLoading] = useState(true);
    const [columnHelper, setColumnHelper] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const handleKeyDOwn = useCallback(
      (e) => {
        const key = e.key;
        if (key === "ArrowUp") {
          if (
            activeRowId &&
            [].concat.apply([], data?.pages)[activeRowId - 2]
          ) {
            tableContainerRef.current.scrollTo({
              top: tableContainerRef.current.scrollTop + 5,
              behavior: "smooth",
            });
            rowClickHandler({
              event: e,
              index: (activeRowId - 2).toString(),
              rowData: [].concat.apply([], data?.pages)[activeRowId - 2],
            });
          }
        } else if (key === "ArrowDown") {
          if (activeRowId && [].concat.apply([], data?.pages)[activeRowId]) {
            tableContainerRef.current.scrollTo({
              top: tableContainerRef.current.scrollTop - 5,
              behavior: "smooth",
            });
            rowClickHandler({
              event: e,
              index: activeRowId.toString(),
              rowData: [].concat.apply([], data?.pages)[activeRowId],
            });
          }
        }
      },
      [activeRowId]
    );

    useEffect(() => {
      window.addEventListener("keydown", handleKeyDOwn);
      return () => {
        window.removeEventListener("keydown", handleKeyDOwn);
      };
    }, [activeRowId]);

    const rowClickHandler = (event) => {
      setDetail1Func("loading");
      setDetail2Func("loading");
      setDetail3Func("loading");
      setDetail4Func("loading");
      setDetailLinks("loading");
      setRowAnnotations("loading");
      setSelectedRow(event.index);
      setActiveRowId(event.rowData.___row___);
      safeLogger(event.rowData);

      if (reportMeta.project_type === "acnv") {
        setIgvLocation(
          event.rowData["chromosome"] +
            ":" +
            event.rowData["start"] +
            "-" +
            event.rowData["end"]
        );
        return;
      }

      try {
        setDetail1Func(JSON.parse(event.rowData["details1"]));
      } catch (error) {
        safeLogger(event.rowData["details1"]);
        safeLogger("invalid json");
        enqueueSnackbar(t("Invalid JSON object for 'Main Details'!"), {
          variant: "error",
        });
        setDetail1Func([]);
      }

      if (event.rowData["details6"]) {
        try {
          setDetail6Func(JSON.parse(event.rowData["details6"]));
        } catch (error) {
          safeLogger(event.rowData["details6"]);
          safeLogger("invalid json");
          enqueueSnackbar(t("Invalid JSON object for 'Bio Markeres'!"), {
            variant: "error",
          });
          setDetail6Func([]);
        }
      }

      const details1Data = [];
      const details2Data = [];
      const details3Data = [];
      const details4Data = [];
      const details6Data = [];
      const linksData = [];
      let uniprot = "";
      const data = Object.entries(event.rowData);
      const specifiedValues = [];
      for (let i = 0; i < data.length; i++) {
        const tmpKey = data[i][0];
        for (let j = 0; j < tableColDetails.length; j++) {
          const tableColDetail = tableColDetails[j];
          if (tableColDetail?.tab && tableColDetail.name === tmpKey) {
            specifiedValues.push(data[i]);
            break;
          }
        }

        if (
          tmpKey !== "___row___" &&
          tmpKey !== "___annotation___" &&
          tmpKey !== "annotated"
        ) {
          if (defaultColDetailsObject[tmpKey]) {
            if (
              defaultColDetailsObject[tmpKey].representation.kind.split(
                "_"
              )[0] === "tabbed"
            ) {
              const groupName =
                defaultColDetailsObject[tmpKey].representation.options
                  .group_name;

              switch (groupName.toLowerCase()) {
                case "details1":
                  details1Data.push(data[i]);
                  break;
                case "details2":
                  details2Data.push(data[i]);
                  break;
                case "details3":
                  details3Data.push(data[i]);
                  break;
                case "details4":
                  details4Data.push(data[i]);
                  break;
                case "details6":
                  details6Data.push(data[i]);
                  break;
                default:
                  break;
              }
            } else if (
              defaultColDetailsObject[tmpKey].name === "links.uniprot_link" ||
              defaultColDetailsObject[tmpKey].name === "links.swissprot"
            ) {
              uniprot = data[i][1].split("https://www.uniprot.org/uniprot/")[1];
            } else if (
              defaultColDetailsObject[tmpKey].representation.kind ===
              "sidebar_link"
            ) {
              // safeLogger(defaultColDetailsObject[tmpKey]);
              linksData.push(data[i]);
            }
          }
        }
      }
      setUniprot(uniprot);
      setDetail2Func(details2Data);
      setDetail3Func(details3Data);
      setDetail4Func(details4Data);
      setSpecifiedValues(specifiedValues);
      setRsID(
        event.rowData[
          reportMeta.version >= 12 ? "main.rs_id" : "main.existing_variation"
        ]
      );
      if (event?.rowData && event?.rowData[identifierColumn]) {
        setIgvLocation(event?.rowData[identifierColumn]);
      } else {
        if (identifierColumn) {
          setIgvLocation(event?.rowData[identifierColumn.toLowerCase()]);
        }
      }
      setDetailLinks(linksData);
      setRowData(event?.rowData);
      setRowAnnotations(event.rowData["___annotation___"]);
    };

    const scrollTop = useCallback(() => {
      tableContainerRef?.current?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, []);

    const recursiveCalculateIsNull = async (rules) => {
      let tmpRules = [...rules];

      for (let i = 0; i < tmpRules.length; i++) {
        let rule = { ...tmpRules[i] };
        if (rule.hasOwnProperty("rules")) {
          rule.rules = await recursiveCalculateIsNull(rule.rules);
        } else {
          if (["isNull", "isNotNull"].includes(rule.operator)) {
            if (defaultColDetailsObject[rule.field].kind === "enumeration")
              rule = {
                combinator: rule.operator === "isNull" ? "or" : "and",
                rules: [
                  rule,
                  {
                    value: "",
                    operator: rule.operator === "isNull" ? "=" : "!=",
                    field: rule.field,
                  },
                ],
              };
          }
        }
        tmpRules[i] = rule;
      }
      return [...tmpRules];
    };

    const fetch = async (isAfter, { pageParam = 0 }) => {
      const body = {};
      if (Object.keys(tableSortState).length) {
        body["ordering"] = tableSortState;
      }
      if (
        Object.keys(tableFilterState).length ||
        (advanceFilter &&
          Object.keys(advanceFilter).length &&
          advanceFilter.rules.length > 0)
      ) {
        let filterState = {
          combinator: "and",
          rules: [],
        };
        if (!advanceFilter) {
          await Object.entries(tableFilterState).map(async (filter, index) => {
            const name = filter[0];
            const values = filter[1];
            if (defaultColDetailsObject[name].kind === "enumeration") {
              const tempValue = {
                combinator: "or",
                rules: [],
              };
              for (const value of values) {
                tempValue.rules.push({
                  field: name,
                  operator: "=",
                  // value must be string
                  value: value,
                });
              }
              // values.forEach((value) => {
              //   filterState.rules.push({
              //     field: name,
              //     operator: "=",
              //     // value must be string
              //     value: value,
              //   });
              // });
              filterState.rules.push(tempValue);
            } else if (defaultColDetailsObject[name].kind === "numeric") {
              await values.forEach((value, index) => {
                filterState.rules.push({
                  combinator: "or",
                  rules: [
                    {
                      field: name,
                      operator: index === 0 ? ">=" : "<=",
                      // value must be string
                      value: value.toString(),
                    },
                    {
                      field: name,
                      operator: "isNull",
                      value: "",
                    },
                  ],
                });
              });
            } else if (defaultColDetailsObject[name].kind === "boolean") {
              filterState.rules.push({
                field: name,
                operator: "=",
                // value must be string
                value: values,
              });
            } else if (defaultColDetailsObject[name].kind === "counted") {
              values.forEach((value, innerIndex) => {
                if (isOrable) {
                  if (innerIndex === 0) {
                    filterState.rules.push({
                      combinator: "or",
                      rules: [],
                    });
                    // filterState.rules.push({
                    //   field: name,
                    //   operator: "notIn",
                    //   value: "",
                    // });
                  }
                  filterState.rules[index].rules.push({
                    field: name,
                    operator: "in",
                    value: value,
                  });
                } else {
                  filterState.rules.push({
                    field: name,
                    operator: "in",
                    // value must be string
                    value: value,
                  });
                }
              });
            }
          });
        } else {
          let rules = await recursiveCalculateIsNull(advanceFilter.rules);
          filterState = { ...advanceFilter, rules: rules };
        }
        body["filters"] = filterState;
      }
      if (Object.keys(tableFilterTypes).length) {
        body["filterTypes"] = tableFilterTypes;
      }
      let newData;
      await apiLoadReportVariantData(
        reportId,
        userObject.organizations[0].id,
        pageParam,
        PAGE_SIZE,
        body,
        (newdata, status) => {
          if (isFetchingFirstTime) setIsFetchingFirstTime(false);
          if (dynamicRowCount !== newdata.count) {
            setDynamicRowCount(newdata.count);
            setTotalRowCount(newdata.count);
          }

          newData = newdata.results;
          let tmpResult = Array.from(newdata.results);
          let is_calculated = [];
          tmpResult.splice(0, 5).forEach((data) => {
            if (
              data["vfreq_in_house"] === null ||
              data["vfreq_in_cloud"] === null
            ) {
              is_calculated.push("not_calculated");
            }
          });

          if (is_calculated.length === 5) {
            setIsCalculated(true);
          } else {
            setIsCalculated(false);
          }
          setPage((prev) => prev + 1);
        }
      );
      return newData;
    };

    const {
      data,
      fetchNextPage,
      isFetchingNextPage,
      isFetched,
      isRefetching,
      fetchStatus,
      status,
      refetch,
    } = useInfiniteQuery({
      queryKey: ["report", tableFilterState, tableSortState, advanceFilter],
      queryFn: async (e) => await fetch(false, e),
      getNextPageParam: (lastPage, allPages) => {
        return allPages.length;
      },
      cacheTime: 5000,
      refetchOnWindowFocus: false,
    });

    const tableContainerRef = React.useRef(null);

    // const geneState = useSelector((state) => state.activeGeneFiltersVariant);
    const dispatch = useDispatch();

    useEffect(() => {
      if (status === "success" && !isFetchingNextPage && !isRefetching) {
        scrollTop();
        queryClient.resetQueries({
          queryKey: ["report", tableFilterState, tableSortState, advanceFilter],
          exact: true,
        });
        setPage(0);
        refetch();
      }
    }, [tableFilterState, tableSortState, advanceFilter]);

    const handleReorderColumnData = useCallback(
      (result) => {
        if (!result.destination) {
          return;
        }

        if (result.destination.index === result.source.index) {
          return;
        }

        const cols = reorder(
          visibleCols,
          result.source.index,
          result.destination.index
        );

        setVisibleCols(cols);
      },
      [visibleCols]
    );

    useEffect(() => {
      // table columns are defined here
      const tmpColumnsData = visibleCols;

      const tmpColumns = [];
      let tmpColumnHelper = {};
      tmpColumnsData.forEach((col) => {
        if (!defaultColDetailsObject[col.name]) return;
        col.representation.width = 100;
        tmpColumnHelper = { ...tmpColumnHelper, [col.name]: col };
        tmpColumns.push(
          tableHelper.accessor((row) => row[col.name], {
            id: col.name,
            header: () => (
              <Tooltip
                title={getTranslated(col.description) ?? ""}
                placement="top"
              >
                <span>{getTranslated(col.label)}</span>
              </Tooltip>
            ),
            // header:col.label,
            cell: (info) => info.getValue(),
            footer: (info) => info.column.id,
          })
        );
      });
      setColumnHelper(tmpColumnHelper);
      setColumns(tmpColumns);
    }, [visibleCols, tableColDetails]);

    const geneState = useSelector((state) => state.activeGeneFilters);

    useEffect(() => {
      if (
        tableFilterState[
          reportMeta.version >= 12 ? "main.gene_symbol" : "main.symbol"
        ]
      ) {
        dispatch(
          updateFilter(
            Object.assign(geneState, {
              gene: tableFilterState[
                reportMeta.version >= 12 ? "main.gene_symbol" : "main.symbol"
              ].reduce((a, v) => ({ ...a, [v]: true }), {}),
            })
          )
        );
      }
    }, [tableFilterState]);

    const handleTagged = (id) => {
      const mod = Math.floor(((id - 1) % PAGE_SIZE) / PAGE_SIZE);
      const moddedId = (id - 1) % PAGE_SIZE;
      data.pages[mod][moddedId]["___tagged___"] =
        !data?.pages[mod][moddedId]["___tagged___"];
    };

    if (status !== "success" && !isFetched && isFetchingFirstTime) {
      return LoadingComponent(virtualTableHeight);
    } else {
      return (
        <VirtualizedTable
          columnHelper={columnHelper}
          rowClickHandler={rowClickHandler}
          reportId={reportId}
          virtualTableHeight={virtualTableHeight}
          setCheckedRows={setCheckedRows}
          handleReorderColumnData={handleReorderColumnData}
          fetchNextPage={fetchNextPage}
          defaultColDetailsObject={defaultColDetailsObject}
          checkedRows={checkedRows}
          reportMeta={reportMeta}
          setColDetails={setColDetails}
          userObject={userObject}
          loading={isRefetching || status !== "success"}
          ref={tableContainerRef}
          dynamicRowCount={dynamicRowCount}
          appConf={appConf}
          selectedRow={selectedRow}
          tableColDetails={tableColDetails}
          t={t}
          page={page}
          fetchStatus={fetchStatus}
          hpo={hpo}
          scrollTop={scrollTop}
          colDetails={colDetails}
          isFetching={isFetchingNextPage}
          handleTagged={handleTagged}
          filters={{
            tableSortState: tableSortState,
            setTableSortState: setTableSortState,
            tableFilterState: tableFilterState,
            setTableFilterState: setTableFilterState,
          }}
          columns={columns}
          data={[].concat.apply([], data?.pages)}
        />
      );
    }
  }
);

const mapStateToProps = (state) => ({
  appConf: state.appConf,
  userObject: state.userObject,
  advanceFilter: state.advanceFilter,
});

const NewReportVirtualizedTable = connect(
  mapStateToProps,
  null
)(NewReportVirtualizedTableConnected);

export default NewReportVirtualizedTable;

export const VirtualizedTable = React.memo(
  React.forwardRef(
    (
      {
        columns,
        selectedRow,
        rowClickHandler,
        dynamicRowCount,
        data,
        tableColDetails,
        defaultColDetailsObject,
        filters,
        reportId,
        loading,
        appConf,
        setColDetails,
        scrollTop,
        setCheckedRows,
        colDetails,
        fetchStatus,
        userObject,
        virtualTableHeight,
        checkedRows,
        isFetching,
        hpo,
        handleTagged,
        handleReorderColumnData,
        fetchNextPage,
        reportMeta,
        page,
        columnHelper,
      },
      ref
    ) => {
      const { t } = useTranslation();
      const classes = useStyles();
      let flatData = data;

      const table = useReactTable({
        data: flatData,
        columns,
        columnResizeMode: "onChange",
        getCoreRowModel: getCoreRowModel(),
      });

      const { rows } = table.getRowModel();
      const rowVirtualizer = useVirtual({
        parentRef: ref,
        size: rows.length,
        estimateSize: React.useCallback(() => 50, []),
        overscan: 50,
      });
      const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

      const paddingTop =
        virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
      const paddingBottom =
        virtualRows.length > 0
          ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
          : 0;

      const update = (event) => {
        if (event.target) {
          const { scrollHeight, scrollTop, clientHeight } = event.target;
          if (
            scrollHeight - scrollTop - clientHeight < 350 &&
            !isFetching &&
            fetchStatus === "idle" &&
            flatData.length < dynamicRowCount
          ) {
            // fetchNextPage({
            //   pageParam: page,
            // });
            fetchNextPage();
          }
        }
      };

      return (
        <div
          ref={ref}
          onScroll={update}
          style={{
            position: "relative",
            maxHeight: virtualTableHeight,
            minHeight: virtualTableHeight,
            overflow: "scroll",
          }}
        >
          <VariantTable
            rows={rows}
            handleTagged={handleTagged}
            virtualRows={virtualRows}
            isFetching={isFetching}
            reportId={reportId}
            colDetails={colDetails}
            virtualTableHeight={virtualTableHeight}
            setColDetails={setColDetails}
            paddingTop={paddingTop}
            handleReorderColumnData={handleReorderColumnData}
            defaultColDetailsObject={defaultColDetailsObject}
            paddingBottom={paddingBottom}
            selectedRow={selectedRow}
            dynamicRowCount={dynamicRowCount}
            userObject={userObject}
            table={table}
            reportMeta={reportMeta}
            loading={loading}
            setCheckedRows={setCheckedRows}
            hpo={hpo}
            tableColDetails={tableColDetails}
            appConf={appConf}
            checkedRows={checkedRows}
            rowClickHandler={rowClickHandler}
            columnHelper={columnHelper}
            filters={filters}
            end={
              <End
                t={t}
                columns={columns}
                end={flatData.length >= dynamicRowCount && flatData.length >= 0}
                isFetching={isFetching}
              />
            }
            classes={classes}
          />
          <TableScrollToTop scrollTop={scrollTop} />
        </div>
      );
    }
  )
);

const TableScrollToTop = React.memo(({ scrollTop }) => {
  return (
    <Button
      onClick={scrollTop}
      style={{
        backgroundColor: "#388e3c",
        color: "white",
        position: "sticky",
        bottom: "3%",
        opacity: 0.8,
        left: "95%",
      }}
    >
      <ArrowDropUp />
    </Button>
  );
});

const Loading = React.memo(function Loading(props) {
  return (
    <TableRow
      style={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      {props.columns.map((column, index) => (
        <TableCell key={index}>
          <Skeleton
            key={props.val}
            style={{
              width: "100%",
            }}
            width={"100%"}
            animation="wave"
          />
        </TableCell>
      ))}
    </TableRow>
  );
});

const Complete = React.memo(function Complete(props) {
  const { t } = useTranslation();
  return (
    <TableRow
      style={{
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {props.columns.map((column, index) => {
        return (
          <TableCell
            key={index}
            style={{
              margin: "auto",
            }}
          >
            {index === props.halfColumns ? t("All data is displayed.") : ""}
          </TableCell>
        );
      })}
    </TableRow>
  );
});

export const End = React.memo(({ end, columns, isFetching }) => {
  const halfColumns = Math.ceil(columns.length / 2);
  return (
    <>
      {isFetching &&
        Array.from(Array(4).keys()).map((val, index) => (
          <Loading val={val} key={index} columns={columns}></Loading>
        ))}
      {end && <Complete halfColumns={halfColumns} columns={columns}></Complete>}
    </>
  );
});

const PAGE_SIZE = 200;
function LoadingComponent(virtualTableHeight) {
  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        height: virtualTableHeight,
      }}
    >
      <CircularProgress
        style={{ top: "48%", left: "48%", position: "absolute" }}
      />
    </div>
  );
}
