/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { loadAppConf, loadOrganizationData, loadUser } from "src/redux/actions";
import {
  apiGetOrganizationDetails,
  apiLoadAppConf,
  apiLoadProfile,
  apiUpdateProfile,
} from "src/api";
import safeLogger from "src/services/safeLogger";
import { useNavigate } from "react-router-dom";
import { getUserActivePermissions } from "./utils";
import { adaptUserSettings } from "src/services/migrations";
import _ from "lodash";
import {
  apiAddToSystemStatusChoices,
  apiGetOrganizationRedux,
  apiLoadOrganizationKits,
} from "src/api/endpoints";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
}));

function mapDispatchToProps(dispatch) {
  return {
    loadUser: (userData) => dispatch(loadUser(userData)),
    loadAppConf: (confData) => dispatch(loadAppConf(confData)),
    loadOrganizationData: (confData) =>
      dispatch(loadOrganizationData(confData)),
  };
}

const mapStateToProps = (state) => {
  return {
    authToken: state.authToken || localStorage.getItem("mbiAuth"),
    userObject: state.userObject,
    appConf: state.appConf,
    organization: state.organization,
  };
};

const ConnectedAppInitiator = (props) => {
  const {
    appConf,
    loadAppConf,
    userObject,
    loadUser,
    organization,
    loadOrganizationData,
  } = props;
  const [user, setUser] = useState();
  const [runnedSamples, setRunnedSamples] = useState();
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    safeLogger("NODE_ENV: ", process.env.API_URL);
    safeLogger("REACT_APP_ENV: ", process.env.REACT_APP_ENV);

    if (!userObject && !user) {
      apiLoadProfile(null, (data, status) => {
        if (status === 200) {
          let userData = data;
          //check user settings and apply migrations if necessary
          const adaptedSettings = adaptUserSettings(userData.settings);

          if (adaptedSettings) {
            apiUpdateProfile({ settings: adaptedSettings }, (data, status) => {
              if (status === 200) {
                window.location.reload();
                // loadUser(data);
              }
            });
          }

          // selecting active organization
          if (userData.organizations.length > 1) {
            if (!localStorage.getItem("activeOrganizationId")) {
              navigate("/select-organization");
            } else {
              for (let i = 0; i < userData.organizations.length; i++) {
                if (
                  userData.organizations[i].id ===
                  parseInt(localStorage.getItem("activeOrganizationId"))
                ) {
                  userData["activeOrganization"] = userData.organizations[i];
                  break;
                }
              }
            }

            // get the user data with active organization and set active permissions
            if (userData["activeOrganization"]) {
              apiLoadProfile(
                userData["activeOrganization"].id,
                (data, status) => {
                  if (status === 200) {
                    data["activeOrganization"] = userData["activeOrganization"];
                    data["activePermissions"] = getUserActivePermissions(data);
                    userData = data;
                    loadUser(userData);
                  }
                }
              );
            } else {
              localStorage.removeItem("activeOrganizationId");
            }
          } else {
            if (!localStorage.getItem("activeOrganizationId")) {
              localStorage.setItem(
                "activeOrganizationId",
                userData.organizations[0].id
              );
            }
            userData["activeOrganization"] = userData.organizations[0];
            userData["activePermissions"] = getUserActivePermissions(userData);
          }

          const creatable_projects = [
            "acnv",
            "duo",
            "exome_bam",
            "exome_fastq",
            "exome_vcf",
            "trio",
          ];

          if (userData.settings.hasOwnProperty("report")) {
            let isUpdated = 0;
            let tmpReportObj = userData.settings.report;
            for (const report in userData.settings.report) {
              if (creatable_projects.includes(report)) {
                const reportObj = userData.settings.report[report];
                if (!reportObj.charts) {
                  reportObj.charts = {};
                  isUpdated++;
                }
                if (!reportObj.defaultDetailsTab) {
                  reportObj.defaultDetailsTab = "main";
                  isUpdated++;
                }
                if (!reportObj.defaultMainTab) {
                  reportObj.defaultMainTab = "variant_table";
                  isUpdated++;
                }
                if (!reportObj.statistics) {
                  reportObj.statistics = {};
                  isUpdated++;
                }
                tmpReportObj = {
                  ...tmpReportObj,
                  [report]: reportObj,
                };
              }
            }
            if (isUpdated > 0) {
              userData.settings.report = tmpReportObj;
              apiUpdateProfile({ settings: userData.settings }, () => {});
            }
          }

          setRunnedSamples(userData);
          setUser(userData);

          loadUser(userData);
          window.hsConversationsSettings = {
            loadImmediately: false,
            identificationEmail: data.email,
          };
        }
      });
    }
    if (userObject && userObject["activeOrganization"]) {
      apiGetOrganizationRedux(
        userObject["activeOrganization"].id,
        (data, status) => {
          if (status === 200) {
            if (!data.status_choices.length)
              apiAddToSystemStatusChoices(data.id);
            setRunnedSamples(data);
            loadOrganizationData(data);
          }
        }
      );
    }
  }, [appConf, userObject]);

  useEffect(() => {
    if (userObject && userObject["activeOrganization"] && appConf) {
      apiLoadOrganizationKits(
        userObject["activeOrganization"].id,
        (kits, status) => {
          if (status === 200) {
            for (const kit in kits) {
              kits[kit] = kits[kit].label;
            }
            appConf.entities.kits = { ...kits, ...appConf.entities.kits };
            loadAppConf(appConf);
          }
        }
      );
    }
  }, []);

  useEffect(() => {
    if (_.isEmpty(appConf) && !_.isEmpty(userObject)) {
      apiLoadAppConf((data, status) => {
        if (status === 200) {
          // TODO get language from backend
          data["lang"] = userObject.settings.general["defaultLang"];
          for (const kit in data.entities.kits) {
            data.entities.kits[kit] = data.entities.kits[kit].label;
          }
          safeLogger(data);
          loadAppConf(data);
        } else if (status === 401) {
          localStorage.removeItem("mbiAuth");
          localStorage.removeItem("activeOrganizationId");
          navigate("/signin");
        }
      });
    }
  }, [userObject]);

  return <CircularProgress className={classes.spinner} />;
};

const AppInitiator = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedAppInitiator);

export default AppInitiator;
