/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Variants from "./components/variants";
import {
  alpha,
  Box,
  Button,
  Divider,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import CustomPdfInput from "./components/input";
import { getTranslated } from "../../reports/utils/helpers";
import { apiCustomReport, apiGetReport } from "src/api/endpoints";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import moment from "moment";

const patientInformation = {
  sender: [
    { label: "Hospital", type: "string", name: "hospital" },
    { label: "Physician", type: "string", name: "physician" },
    { label: "Date of Request", type: "date", name: "date_of_request" },
  ],
  patient: [
    { label: "Patient ID", type: "string", name: "patient_id" },
    { label: "Date of Birth", type: "date", name: "date_of_birth" },
    { label: "Indication", type: "string", name: "indication" },
  ],
  sample: [
    { label: "Sample ID", type: "string", name: "sample_id" },
    { label: "Sample Type", type: "string", name: "sample_type" },
    { label: "Date of Collection", type: "date", name: "date_of_collection" },
  ],
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    display: "flex",
    margin: "auto",
    justifyContent: "center",
    flexDirection: "column",
    marginTop:"40px",
  },
  head: {
    padding: theme.spacing(2),
  },
  table: {
    padding: theme.spacing(2),
  },
  tableHead: {
    backgroundColor: theme.palette.primary.main,
    "& th": {
      color: `white !important`,
    },
  },
  patientInformation: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    gap: theme.spacing(4),
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  patientInformationColumn: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  primary_tissues: {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.1)}`,
  },
  secondary_findings: {
    backgroundColor: `${alpha(theme.palette.secondary.main, 0.1)}`,
  },
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: `${theme.spacing(2)}px 0`,
  },
  rightTableHeader: {
    display: "flex",
    gap: theme.spacing(2),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2),
  },
  buttonGroup: {
    display: "flex",
    gap: theme.spacing(2),
  },
  successButton: {
    background: `${alpha(theme.palette.success.main, 0.1)}`,
    color: theme.palette.success.main,
    "&:hover": {
      background: `${alpha(theme.palette.success.main, 0.3)}`,
    },
  },
}));

function GermlinePDF({ variants, schema, reportId, files }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [pdfSchema, setPdfSchema] = useState();
  const [initial, setInitial] = useState(true);
  const [commentsSchema, setCommentsSchema] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [extraFields, setExtraFields] = useState(() => {
    const today = moment().format("YYYY-MM-DD");
    return {
      secondary_findings: [],
      primary_tissues: [],
      summary: "",
      limit_of_assay: "",
      variant_comments: {},
      sample_information: {
        sender: { date_of_request: today },
        patient: { date_of_birth: today },
        sample: { date_of_collection: today }
      }
    };
  });

  useEffect(() => {
    if (initial) {
      const primaryTissues = [];
      variants.results.map((variant) => primaryTissues.push(variant.___row___));
      extraFields.primary_tissues = primaryTissues;
      setExtraFields({ ...extraFields });
      setInitial(false);
    }
  }, [variants]);

  useEffect(() => {
    const tmpPdfSchema = {};
    const tmpPdfCommentSchema = {};
    let i = 0;
    for (const key in schema) {
      const column = schema[key];
      if (
        column.representation.options.hasOwnProperty("pdf") &&
        column.representation.options.pdf.hasOwnProperty("visible_in_table") &&
        column.representation.options.pdf.visible_in_table
      ) {
        if (i <= 1) {
          i++;
          tmpPdfCommentSchema[key] = column;
        }
        tmpPdfSchema[key] = column;
      }
    }
    setPdfSchema(tmpPdfSchema);
    setCommentsSchema(tmpPdfCommentSchema);
  }, []);

  const onChangeSampleInformation = (name, value, key) => {
    if (extraFields.sample_information) {
      extraFields.sample_information[key] = {
        ...extraFields.sample_information[key],
        [name]: value,
      };
      setExtraFields({ ...extraFields });
    }
  };

  const onChangeInput = (name, value) => {
    extraFields[name] = value;
    setExtraFields({ ...extraFields });
  };
  const downloadPdf = () => {
    const currentLanguage = i18n.language.split('_')[0]; 
    apiCustomReport(
      reportId,
      variants.results.map((variant) => variant.___row___),
      { files: files, ...extraFields },
      (data, status) => {
        if (status === 200) {
          apiGetReport(`${data.download_address}?l=${currentLanguage}`, (blobData, status) => {
            if (status === 200) {
              const url = window.URL.createObjectURL(blobData);
              window.open(url);
            } else {
              enqueueSnackbar(t(getTranslated("PDF download failed")), { variant: "error" });
            }
          });
        } else {
          enqueueSnackbar(t(getTranslated("An error occurred while generating the report.")), { variant: "error" });
        }
      }
    );
  };
  
  // TODO: Save pdf to database
  const savePdf = () => {};

  // TODO: Close tab if seleceted variants is wrong asd
  const cancelPdf = () => {};
  if (pdfSchema && variants) {
    return (
      <Box className={classes.root}>
        <Box>
          <Typography className={classes.head} variant="h3">
            {t(getTranslated("Patient Information"))} 
          </Typography>
          <Box className={classes.patientInformation}>
            {Object.keys(patientInformation).map((key, index) => {
              const inputs = patientInformation[key];
              return (
                <Box key={index} className={classes.patientInformationColumn}>
                  {inputs.map((input, index) => (
                    <CustomPdfInput
                      input={input}
                      label={key}
                      objectKey={key}
                      value={extraFields.sample_information}
                      key={index}
                      onChange={onChangeSampleInformation}
                    />
                  ))}
                </Box>
              );
            })}
          </Box>
        </Box>
        <Divider />
        <Box>
          <Typography className={classes.head} variant="h3">
            {t(getTranslated("Summary"))}
          </Typography>
          <Box className={classes.patientInformation}>
            <TextField
              style={{ paddingTop: 8 }}
              fullWidth
              multiline
              name="summary"
              minRows={4}
              value={extraFields.summary}
              onChange={(e) => onChangeInput(e.target.name, e.target.value)}
              variant="outlined"
            />
          </Box>
        </Box>
        <Divider />
        <Box>
          <Typography className={classes.head} variant="h3">
            {t(getTranslated("Limit Of Assay"))}
          </Typography>
          <Box className={classes.patientInformation}>
            <TextField
              style={{ paddingTop: 8 }}
              fullWidth
              multiline
              name="limit_of_assay"
              minRows={4}
              value={extraFields.limit_of_assay}
              onChange={(e) => onChangeInput(e.target.name, e.target.value)}
              variant="outlined"
            />
          </Box>
        </Box>
        <Divider />
        <Box className={classes.table}>
          <Box className={classes.tableHeader}>
            <Box>
              <Typography variant="h3">
                {t(getTranslated("Variants"))}
              </Typography>
            </Box>
            <Box className={classes.rightTableHeader}>
              <Typography color="primary">
                {t(getTranslated("Primary Tissues"))}
              </Typography>
              <Typography color="secondary">
                {t(getTranslated("Secondary Findings"))}
              </Typography>
            </Box>
          </Box>
          <Variants
            variants={variants}
            extraFields={extraFields}
            setExtraFields={setExtraFields}
            classes={classes}
            schema={pdfSchema}
          />
        </Box>
        <Box className={classes.table}>
          <Box className={classes.tableHeader}>
            <Box>
              <Typography variant="h3">
                {t(getTranslated("Comment to Variants"))}
              </Typography>
            </Box>
            <Box className={classes.rightTableHeader}>
              <Typography color="primary">
                {t(getTranslated("Primary Tissues"))}
              </Typography>
              <Typography color="secondary">
                {t(getTranslated("Secondary Findings"))}
              </Typography>
            </Box>
          </Box>
          <Variants
            variants={variants}
            extraFields={extraFields}
            setExtraFields={setExtraFields}
            classes={classes}
            schema={commentsSchema}
          />
        </Box>
        <Box className={classes.buttons}>
          <Box className={classes.buttonGroup}>
            <Button
              variant="contained"
              className={classes.successButton}
              onClick={savePdf}
            >
              {t(getTranslated("Save"))}
            </Button>
            <Button variant="contained" color="primary" onClick={downloadPdf}>
              {t(getTranslated("Download"))}
            </Button>
          </Box>
          <Button color="secondary" onClick={cancelPdf}>
            {t(getTranslated("Cancel"))}
          </Button>
        </Box>
      </Box>
    );
  }
}

export default GermlinePDF;

