import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  AppBar,
  Button,
  CircularProgress,
  colors,
  Dialog,
  IconButton,
  makeStyles,
  Slide,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { TableLayoutEditor } from "../settings/tableConfigPage/tableLayoutEditor";
import {
  Close as CloseIcon,
  Save as SaveIcon,
  Tune as TuneIcon,
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import TabPanel from "src/components/tabPanel";
import HboFilter from "./hboFilter";
import AdvanceFilter from "./advancedFilter";
import PanelApp from "./panel";
import { useDispatch, useSelector } from "react-redux";
import { updateAdvanceFilter, updateFilterStats } from "src/redux/actions";
import { getGeneSymbolKey } from "./utils/helpers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    color: `${colors.grey[100]} !important`,
    flex: 1,
  },
}));

const VariantTableLayoutEditor = (props) => {
  const classes = useStyles();
  const {
    appConf,
    userObject,
    schemaId,
    colDetails,
    setColDetails,
    defaultColObj,
    visibleCols,
    open,
    setOpen,
    setHpoFilter,
    reportFiles,
    reportMeta,
    setVisibleCols,
    defaultColDetails,
    identifierColumn,
    version,
    advanceFilter,
    setAdvanceFilter,
  } = props;
  const [columnOptions, setColumnOptions] = useState(undefined);
  const [selectedValues, setSelectedValues] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [intersection, setIntersection] = useState(true);
  const [pheno, setPheno] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reduxAdvanceFilter = useSelector((state) => state.advanceFilter);
  useEffect(() => {
    setAdvanceFilter(reduxAdvanceFilter ? reduxAdvanceFilter : advanceFilter);
  }, [reduxAdvanceFilter]);

  const params = useParams();

  useEffect(() => {
    setAdvanceFilter({
      combinator: "or",
      rules: [],
    });
    dispatch(updateAdvanceFilter(undefined));
  }, [params?.id]);

  const tabList = [
    {
      label: t("Table Layout Editor"),
      component: (
        <TableLayoutEditor
          appConf={appConf}
          userObject={userObject}
          schemaId={schemaId}
          visibleCols={visibleCols}
          version={version}
          setVisibleCols={setVisibleCols}
          columnOptions={columnOptions}
          setColumnOptions={setColumnOptions}
        />
      ),
    },
    {
      label: t("HPO Filter"),
      component: (
        <HboFilter
          reportFiles={reportFiles}
          intersection={intersection}
          setIntersection={setIntersection}
          pheno={pheno}
          setPheno={setPheno}
          setOpen={setOpen}
        />
      ),
    },
    {
      label: t("Massive Panel"),
      component: (
        <PanelApp
          selectedValues={selectedValues}
          reportFiles={reportFiles}
          setSelectedValues={setSelectedValues}
        />
      ),
    },
    {
      label: t("Advance Filterset"),
      component: (
        <AdvanceFilter
          version={version}
          reportMeta={reportMeta}
          setAdvanceFilter={setAdvanceFilter}
          advanceFilter={advanceFilter}
          fields={defaultColDetails}
        />
      ),
    },
  ];
  const prepareColumns = useCallback(() => {
    const mainTableCols = [];
    const otherCols = [];

    for (let i = 0; i < columnOptions.length; i++) {
      if (defaultColObj[columnOptions[i].name]) {
        if (
          columnOptions[i]?.kind === "enumeration" ||
          columnOptions[i]?.kind === "counted"
        ) {
          const activeFilters = columnOptions[i].filterValues?.length
            ? columnOptions[i].filterValues.reduce(
                (dict, curr) => Object.assign(dict, { [curr]: true }),
                {}
              )
            : columnOptions[i].filterValues;
          columnOptions[i].filterValues = Object.assign(
            {},
            defaultColObj[columnOptions[i].name].filterValues,
            activeFilters
          );
        }
        if (
          columnOptions[i]?.kind === "numeric" &&
          columnOptions[i]?.filterRange
        ) {
          if (
            columnOptions[i]?.filterRange.length === 0 ||
            (columnOptions[i]?.filterRange[0] === "" &&
              columnOptions[i]?.filterRange[1] === "")
          ) {
            delete columnOptions[i]?.filterRange;
          } else {
            if (columnOptions[i]?.filterRange[0] === "") {
              columnOptions[i].filterRange[0] = columnOptions[i].minValue;
            }
            if (columnOptions[i]?.filterRange[1] === "") {
              columnOptions[i].filterRange[1] = columnOptions[i].maxValue;
            }
          }
        }
        if (columnOptions[i]?.representation?.kind === "main_table_cell") {
          mainTableCols.push(columnOptions[i]);
        } else {
          if (
            columnOptions[i]?.representation?.kind === "unspecified" &&
            defaultColObj[columnOptions[i].name].representation.kind !==
              "main_table_cell"
          ) {
            columnOptions[i].representation.kind =
              defaultColObj[columnOptions[i].name]?.representation?.kind;
          }
          otherCols.push(columnOptions[i]);
        }
      }
    }
    return [...mainTableCols, ...otherCols];
  }, [columnOptions]);

  const getUnselectedColumns = () => {
    return defaultColDetails.filter((column) => {
      for (let i = 0; i < columnOptions.length; i++) {
        if (columnOptions[i].name === column.name) {
          return false;
        }
      }

      if (column.representation?.kind === "main_table_cell") {
        column.representation.kind = "unspecified";
      }
      return true;
    });
  };

  useEffect(() => {
    const newColDetails = JSON.parse(JSON.stringify(colDetails));
    const visibleCols = newColDetails.filter((col) => {
      if (col.filterValues) {
        col.filterValues = Object.keys(col.filterValues).reduce(
          (prev, curr) => (col.filterValues[curr] ? [...prev, curr] : prev),
          []
        );
      }
      return col.representation &&
        col.representation.options &&
        (!col?.representation?.options?.hasOwnProperty("visible") ||
          (col?.representation?.options?.hasOwnProperty("visible") &&
            col?.representation?.options?.visible))
        ? col.representation.options.layout_visible
        : false;
    });
    setColumnOptions(visibleCols);
  }, [colDetails]);

  function handleSave(e) {
    let filteredGene = [];
    let newColDetails;
    switch (tabValue) {
      case 0:
        newColDetails = prepareColumns();
        newColDetails.push(...getUnselectedColumns());
        setColDetails([...newColDetails]);
        handleClose();
        return;
      case 1:
        const hpoFilter = [];
        if (intersection) {
          pheno.forEach((item) => {
            hpoFilter.push(`${item.hpo_id} - ${item.hpo_label}`);
            if (filteredGene.length === 0) {
              filteredGene = item.hugo_symbol;
            } else {
              filteredGene = filteredGene.filter((gene) =>
                item.hugo_symbol.includes(gene)
              );
            }
          });
        } else {
          pheno.forEach((item) => {
            hpoFilter.push(`${item.hpo_id} - ${item.hpo_label}`);
            filteredGene = [...filteredGene, ...item.hugo_symbol];
          });
        }
        setHpoFilter(hpoFilter);
        dispatch(
          updateFilterStats({
            [getGeneSymbolKey(reportMeta.pipeline_class, version)]: [],
          })
        );
        dispatch(
          updateFilterStats({
            [getGeneSymbolKey(reportMeta.pipeline_class, version)]:
              filteredGene,
          })
        );
        handleClose();
        return;
      case 2:
        filteredGene = selectedValues.map((selectedValue) => {
          let genes = [];
          Object.values(selectedValue[1]).forEach((values) => {
            if (values.isActive) {
              genes = [
                ...genes,
                ...values.genes
                  .filter((gene) => gene.isActive)
                  .map((gene) => gene.label.replace(/\s/g, "")),
              ];
            }
          });
          return genes;
        });

        dispatch(
          updateFilterStats({
            [getGeneSymbolKey(reportMeta.pipeline_class, version)]: [],
          })
        );

        dispatch(
          updateFilterStats({
            [getGeneSymbolKey(reportMeta.pipeline_class, version)]:
              filteredGene.flatMap((genes) => genes),
          })
        );

        handleClose();
        return;
      case 3:
        dispatch(updateAdvanceFilter(advanceFilter));
        handleClose(updateFilterStats());
        return;
      default:
        newColDetails = prepareColumns();
        newColDetails.push(...getUnselectedColumns());
        setColDetails([...newColDetails]);
        handleClose();
        return;
    }
  }

  function handleClickOpen(e) {
    setOpen(true);
  }

  function handleClose(e) {
    setOpen(false);
  }

  function handleTabChange(e, value) {
    setTabValue(value);
  }

  if (columnOptions) {
    return (
      <React.Fragment>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          startIcon={<TuneIcon />}
        >
          {t("Edit table layout and filters")}
        </Button>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h4" className={classes.title}>
                {t("Edit variant table layout")}
              </Typography>
              <Button
                id="filter_apply_button"
                variant="contained"
                startIcon={<SaveIcon />}
                autoFocus
                color="secondary"
                onClick={handleSave}
              >
                {t("Apply")}
              </Button>
            </Toolbar>
          </AppBar>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            centered
            aria-label="variant-table-tab"
          >
            {tabList.map((value, index) => (
              <Tab
                key={index}
                label={value.label}
                id={
                  index === 0
                    ? "table_layout_editor"
                    : index === 1
                    ? "hpo_filter"
                    : index === 2
                    ? "massive_panel"
                    : "advance_filterset"
                }
              />
            ))}
          </Tabs>
          {tabList.map((value, index) => (
            <TabPanel value={tabValue} index={index} key={index}>
              {value.component}
            </TabPanel>
          ))}
        </Dialog>
      </React.Fragment>
    );
  } else {
    return <CircularProgress />;
  }
};

export default VariantTableLayoutEditor;
