/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { apiLoadReportDetails } from "src/api";
import Page from "src/components/Page";
import {
  Container,
  Grid,
  makeStyles,
  Tabs,
  Tab,
  Box,
  Typography,
  CircularProgress,
  colors,
  Badge,
  Button,
  Breadcrumbs,
  Link,
  Tooltip,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import { VariantDetailTable, VariantDetailGrid } from "./details";
import { FilterStatusBox } from "./filterStatus";
import { IGVBrowser } from "./igvBrowser";
import { DragHandle as DragHandleIcon, Warning } from "@material-ui/icons";
import { Statistics } from "./statistics";
import { useParams, useSearchParams } from "react-router-dom";
import { CNVPlots, CNVTable } from "./cnv";
import { useTranslation } from "react-i18next";
import ReportGenerator from "./reportGenerator";
import SidebarLinks from "./sidebarLinks";
import { connect } from "react-redux";
import safeLogger from "src/services/safeLogger";
import { reportSectionsConf } from "src/config";
import { arrayIntersection } from "src/components/utils";
import RowDataCopier from "./rowDataCopier";
import VariantTableLayoutEditor from "./variantTableLayoutEditor";
import AnnotationsAndComments from "./details/annotationsAndComments";
import { SamplesPopUp } from "./samplesPopUp";
import NewReportVirtualizedTable from "./reportVirtiualizedTable";
import { useDispatch } from "react-redux";
import { clearActiveGeneFilters, updateFilterStats } from "src/redux/actions";
import Protvista from "src/components/protvista";
import ProtvistaUniprot from "protvista-uniprot";
import { apiTableGetSchema } from "src/api/endpoints";
import RelatedProjects from "./relatedProjects";
import SpecifiedValues from "./specifiedValues";
import SubmissionMenu from "./submissions/menu";
import { prepareFiles } from "./utils/helpers";

window.customElements.define("protvista-uniprot", ProtvistaUniprot);

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  dragHandleWrapper: {
    margin: "auto",
    display: "flex",
    "& .drag-handle": {
      cursor: "ns-resize",
      fill: colors.grey[400],
      margin: "auto",
      "&:hover": {
        fill: colors.grey[500],
      },
      "&:active": {
        fill: colors.grey[600],
      },
    },
  },
  pageTabs: {
    "& .MuiTabs-flexContainer": {
      borderBottom: "#C2C2C2 2px solid",
    },
    "& .MuiTabs-indicator": {
      height: "50px",
      borderBottom: theme.palette.secondary.main + " 2px solid",
      backgroundColor: theme.palette.background.paper,
    },
    "& .MuiTab-root": {
      zIndex: 10,
    },
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(4),
  },
  pageTitle: {
    // marginBottom: theme.spacing(4),
    color: theme.palette.title.primary,
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  projectMeta: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      marginRight: 40,
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "start",
      alignItems: "start",
    },
    alignItems: "center",
  },
  projectDetails: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  projectMetaKey: {
    color: theme.palette.title.secondary,
    justifyContent: "center",
    alignItems: "center",
    marginRight: "5px",
  },
  projectMetaValue: {
    color: theme.palette.title.primary,
  },
  variants: {},
  detailsSection: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
  },
  detailsHeader: {
    [theme.breakpoints.up("md")]: {
      paddingRight: "10px",
    },
  },
  detailsLinks: {
    margin: "10px auto",
    [theme.breakpoints.up("md")]: {
      borderLeft: "1px solid #e2e2e2",
    },
    padding: "0 10px",
  },
  tabPanelRoot: {
    height: "inherit",
  },
  specifiedValues: {
    transition: "all 0.1s ease-out 100ms",
    "&:hover": {
      backgroundColor: "rgb(43, 81, 207,0.5)",
    },
    backgroundColor: theme.palette.background.paper,
  },
}));

const TabPanel = (props) => {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      className={classes.tabPanelRoot}
      role="tabpanel"
      hidden={value !== index}
      id={`analysis-tabpanel-${index}`}
      aria-labelledby={`analysis-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const DraggableHandle = (props) => {
  const classes = useStyles();
  let startY;
  const { virtualTableHeight, setVirtualTableHeight } = props;

  const doDrag = (event) => {
    if (virtualTableHeight + event.clientY - startY >= 80) {
      setVirtualTableHeight(virtualTableHeight + event.clientY - startY);
    }
  };

  const stopDrag = (event) => {
    document.documentElement.removeEventListener("mousemove", doDrag, false);
    document.documentElement.removeEventListener("mouseup", stopDrag, false);
  };

  const initDrag = (event) => {
    startY = event.clientY;
    document.documentElement.addEventListener("mousemove", doDrag, false);
    document.documentElement.addEventListener("mouseup", stopDrag, false);
  };

  return (
    <div className={classes.dragHandleWrapper} onMouseDown={initDrag}>
      <DragHandleIcon className="drag-handle" />
    </div>
  );
};

const mapStateToProps = (state) => {
  return { userObject: state.userObject, appConf: state.appConf };
};

const ConnectedReportPage = (props) => {
  const { userObject, appConf } = props;
  const classes = useStyles();
  const [analysisTabValue, setAnalysisTabValue] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [detailTabValue, setDetailTabValue] = useState(0);
  const [refresh, setRefresh] = useState(false);
  // const [columnNames, setColumnNames] = useState(null);
  const { id } = useParams();
  const [reportMeta, setReportMeta] = useState({});
  // const [variantsData, setVariantsData] = useState(null);
  // const [dataObjects, setDataObjects] = useState([]);

  const [mainTabs, setMainTabs] = useState(undefined);
  const [mainTabsOrdered, setMainTabsOrdered] = useState(undefined);
  const [detailsTabsOrdered, setDetailsTabsOrdered] = useState(undefined);

  const [detail1Data, setDetail1Data] = useState(null);
  const [detail2Data, setDetail2Data] = useState(null);
  const [detail3Data, setDetail3Data] = useState(null);
  const [detail4Data, setDetail4Data] = useState(null);
  const [detail6Data, setDetail6Data] = useState(null);
  const [igvLocation, setIgvLocation] = useState(null);
  const [detailLinks, setDetailLinks] = useState(null);
  const [isCalulated, setIsCalculated] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const [isOrable, setIsOrable] = useState(false);
  const [identifierColumn, SetIdentifierColumn] = useState(null);
  const [specifiedValues, setSpecifiedValues] = useState(null);
  const [uniprot, setUniprot] = useState(null);
  const [rowAnnotations, setRowAnnotations] = useState(null);

  const tabData = {
    details1: detail1Data,
    details2: detail2Data,
    details3: detail3Data,
    details4: detail4Data,
    details6: detail6Data,
  };

  const [checkedRows, setCheckedRows] = useState([]);
  const [activeRowId, setActiveRowId] = useState(undefined);
  const [totalRowCount, setTotalRowCount] = useState(undefined);
  const [filteredRowCount, setFilteredRowCount] = useState(undefined);

  const [virtualTableHeight, setVirtualTableHeight] = useState(360); // TODO make user configurable
  // const [variantTableConf, setVariantTableConf] = useState(null);
  // const [tableColDetails, setTableColDetails] = useState(colDetails);
  const [rsID, setRsID] = useState();
  const [tableColDetails, setTableColDetails] = useState(null);
  const [defaultColDetails, setDefaultColDetails] = useState([]);
  const [rowData, setRowData] = useState({});
  const [visibleCols, setVisibleCols] = useState(undefined);
  const [defaultColDetailsObject, setDefaultColDetailsObject] = useState({});
  const [reportFiles, setReportFiles] = useState({});
  const [tableSortState, setTableSortState] = useState({});
  const [openVariantTable, setOpenVariantTable] = useState(false);
  const [tableFilterState, setTableFilterState] = useState({});
  const [pipelineFormMetadata, setPipelineFormMetadata] = useState({});
  const [tableFilterTypes, setTableFilterTypes] = useState({});
  const [hpoFilter, setHpoFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSchemaList = (cols, data, tmpReportMeta, mounted) => {
    cols = cols.columns.map((value) => {
      return {
        ...value,
        filterValues: data.column_unique_values[value.name]
          ? data.column_unique_values[value.name].filter(
              (filter) => filter !== ""
            )
          : [],
        unique_values: data.column_unique_values[value.name]
          ? data.column_unique_values[value.name].filter(
              (filter) => filter !== ""
            )
          : [],
      };
    });

    const processedCols = processColDetails(cols);

    if (mounted) {
      setAnalysisTabValue(
        reportSectionsConf[tmpReportMeta.project_type].tabs[
          userObject.settings.report[tmpReportMeta.project_type][
            "defaultMainTab"
          ]
        ].order ?? 0
      );
      setDetailTabValue(
        reportSectionsConf[tmpReportMeta.project_type].detailsTabs[
          userObject.settings.report[tmpReportMeta.project_type][
            "defaultDetailsTab"
          ]
        ].order ?? 0
      );
      setTableColDetails(processedCols.colDetails);
      setDetailsTabsOrdered(processedCols.detailsTabsOrdered);
      setDefaultColDetailsObject(processedCols.defaultColDetailsObject);
      setDefaultColDetails(processedCols.defaultColDetails);
    }
  };

  const processColDetails = (colDetails) => {
    const tmpColDetailObject = {};
    const tmpDetailsTabs = {};
    for (let i = 0; i < colDetails?.length; i++) {
      if (
        !colDetails[i]?.representation?.options?.hasOwnProperty("visible") ||
        (colDetails[i]?.representation?.options?.hasOwnProperty("visible") &&
          colDetails[i]?.representation?.options?.visible)
      ) {
        if (colDetails[i].representation?.kind.split("_")[0] === "tabbed") {
          if (
            !tmpDetailsTabs[colDetails[i].representation.options.group_name]
          ) {
            tmpDetailsTabs[colDetails[i].representation.options.group_name] = {
              kind: colDetails[i].representation?.kind,
              order: colDetails[i].representation.options.order,
              title: colDetails[i].representation.options.title,
            };
          }
          if (
            !tmpDetailsTabs[colDetails[i].representation.options.group_name]
              .columns
          ) {
            tmpDetailsTabs[
              colDetails[i].representation.options.group_name
            ].columns = [];
          }
          tmpDetailsTabs[
            colDetails[i].representation.options.group_name
          ].columns.push(colDetails[i].name);
        }

        colDetails[i].activeFilterCount = 0;
        if (colDetails[i]?.kind === "numeric") {
          if (colDetails[i].unique_values) {
            colDetails[i]["filterRange"] = colDetails[i].unique_values.slice(0);
            colDetails[i]["minValue"] = colDetails[i].unique_values[0];
            colDetails[i]["maxValue"] = colDetails[i].unique_values[1];
          } else {
            colDetails[i]["filterRange"] = [];
            colDetails[i]["minValue"] = 0;
            colDetails[i]["maxValue"] = 0;
          }
        } else if (colDetails[i]?.kind === "enumeration") {
          if (colDetails[i].unique_values.length) {
            const tmpFilterValues = {};
            for (let j = 0; j < colDetails[i].unique_values.length; j++) {
              tmpFilterValues[colDetails[i].unique_values[j]] = false;
            }
            colDetails[i]["filterValues"] = tmpFilterValues;
          } else {
            colDetails[i]["filterValues"] = {};
          }
        } else if (colDetails[i]?.kind === "boolean") {
          colDetails[i]["filterValues"] = { true: false, false: false };
        } else if (colDetails[i]?.kind === "counted") {
          const tmpFilterValues = {};
          for (let j = 0; j < colDetails[i].unique_values.length; j++) {
            tmpFilterValues[colDetails[i].unique_values[j]] = false;
          }

          colDetails[i]["filterValues"] = tmpFilterValues;
        }
        delete colDetails[i].unique_values;
        tmpColDetailObject[colDetails[i].name] = colDetails[i];
      }
    }
    const tmpDetailsTabsOrdered = [];
    for (let item in tmpDetailsTabs) {
      tmpDetailsTabsOrdered.push({ ...tmpDetailsTabs[item], key: item });
    }
    tmpDetailsTabsOrdered.sort((a, b) => a.order > b.order);
    return {
      colDetails: colDetails,
      defaultColDetailsObject: JSON.parse(JSON.stringify(tmpColDetailObject)),
      defaultColDetails: JSON.parse(JSON.stringify(colDetails)),
      detailsTabs: tmpDetailsTabs,
      detailsTabsOrdered: tmpDetailsTabsOrdered,
    };
  };

  useEffect(() => {
    const column = searchParams.get("column");
    const filter = searchParams.get("filter");

    if (column && filter) {
      dispatch(updateFilterStats({ [column]: [filter] }));
    }
  }, []);

  useEffect(() => {
    let mounted = true;
    setLoading(true);
    apiLoadReportDetails(id, async (data, status) => {
      if (status === 200) {
        let tmpFiles = {};

        let tmpReportMeta = {
          id: data.id,
          name: data.name,
          project: data.project,
          project_name: data.project_name,
          project_type: data.project_type,
          pipeline_class: data.pipeline_class,
          kit: data.kit,
          schema_id: data.schema,
          commented_rows: data.commented_rows,
          column_unique_values: data.column_unique_values,
        };

        setMainTabs(
          tmpReportMeta.pipeline_class !== "nanopore"
            ? reportSectionsConf[tmpReportMeta.project_type].tabs
            : reportSectionsConf["nanopore"].tabs
        );
        const tmpMainTabsOrdered = [];
        for (let item in tmpReportMeta.pipeline_class !== "nanopore"
          ? reportSectionsConf[tmpReportMeta.project_type].tabs
          : reportSectionsConf["nanopore"].tabs) {
          tmpMainTabsOrdered.push({
            ...reportSectionsConf[tmpReportMeta.project_type].tabs[item],
          });
        }
        tmpMainTabsOrdered.sort((a, b) => a.order > b.order);
        setMainTabsOrdered(tmpMainTabsOrdered);
        tmpFiles = prepareFiles(data.files, data.unified_output, data);

        safeLogger("report files: ", tmpFiles);

        if (mounted) {
          setReportFiles(tmpFiles);
        }

        if (mounted) {
          if (data?.pipleine_form_metadata) {
            setPipelineFormMetadata(data.pipleine_form_metadata);
            safeLogger(pipelineFormMetadata);
          }
        }

        let cols;

        if (appConf.schemas[data.project_type].id === data.schema) {
          cols = appConf.schemas[data.project_type];
          // handleSchemaList(cols, data, tmpReportMeta, mounted);
        } else {
          apiTableGetSchema(data.schema, (cols, status) => {
            console.log(cols);
            tmpReportMeta = {
              ...tmpReportMeta,
              schema_version: cols.version,
              version: Number(
                cols.version
                  ? cols.version.replace("v", "").replace("/somatic", "")
                  : ""
              ),
              identifier_column: cols.identifier_column,
              annotable: cols.annotable,
            };
            SetIdentifierColumn(cols.identifier_column);
            setReportMeta(tmpReportMeta);
            handleSchemaList(cols, data, tmpReportMeta, mounted);
          });
        }
      }
    });

    return function cleanup() {
      dispatch(clearActiveGeneFilters());
      mounted = false;
    };
  }, [id]);

  useEffect(() => {
    if (tableColDetails?.length) {
      const tmpVisibleCols = tableColDetails.filter((col, index) => {
        col["globalIndex"] = index;
        return (
          col.representation?.kind === "main_table_cell" &&
          (!col?.representation?.options?.hasOwnProperty("visible") ||
            (col?.representation?.options?.hasOwnProperty("visible") &&
              col?.representation?.options?.visible))
        );
      });
      setVisibleCols(tmpVisibleCols);
    }
  }, [tableColDetails]);

  useEffect(() => {
    if (defaultColDetails.length) {
      setLoading(false);
    }
  }, [defaultColDetails]);

  useEffect(() => {
    if (analysisTabValue !== 1) {
      setDetail1Data(null);
      setDetail2Data(null);
      setDetail3Data(null);
      setDetail4Data(null);
      setIgvLocation(null);
      setDetailLinks(null);
      setRowAnnotations(null);
    }
  }, [analysisTabValue, refresh]);

  const handleAnalysisTabChange = (event, newValue) => {
    setAnalysisTabValue(newValue);
  };
  const handleDetailTabChange = (event, newValue) => {
    setDetailTabValue(newValue);
  };

  const handleSelect = () => {
    setRefresh(!refresh);
  };

  const [open, setOpen] = useState(false);
  const [isOverflow, setIsOverflow] = useState(true);
  const [advanceFilter, setAdvanceFilter] = useState({
    combinator: "or",
    rules: [],
  });

  useEffect(() => {
    if (visibleCols) {
      if (visibleCols.length > 17) {
        setIsOverflow(true);
      }
    }
  }, [visibleCols]);

  useEffect(() => {
    return (e) => {
      setSpecifiedValues([]);
    };
  }, []);

  const handleClickOpen = () => {
    setOpen(!open);
  };

  if (loading) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <Page className={classes.root} title={t("Analysis Report")}>
        <Container
          maxWidth={false}
          style={{
            display: "flex",
            width: "100%",
            padding: "0px 16px",
          }}
        >
          <SpecifiedValues
            tableColDetails={tableColDetails}
            classes={classes}
            defaultColDetailsObject={defaultColDetailsObject}
            setSpecifiedValues={setSpecifiedValues}
            specifiedValues={specifiedValues}
            rowData={rowData}
          />
          <Box
            style={{
              width:
                specifiedValues && specifiedValues.length
                  ? "calc(100% - 300px)"
                  : "100%",
              transition: "all ease 300ms",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  width="100%"
                  justifyContent="start"
                  alignItems="center"
                  flexDirection="row"
                  style={{ gap: 20 }}
                >
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                  >
                    <Typography
                      component="h3"
                      variant="h3"
                      className={classes.pageTitle}
                    >
                      {t("Analysis result")}
                      {isCalulated && (
                        <Tooltip
                          title={t(
                            "Variant frequency is calculating on backgroud."
                          )}
                        >
                          <Warning
                            style={{ marginLeft: 16, color: "orange" }}
                          />
                        </Tooltip>
                      )}
                    </Typography>
                    <Breadcrumbs
                      aria-label="breadcrumb-2"
                      style={{ fontSize: 14 }}
                      separator="›"
                    >
                      <Link href='/projects?page=0&pageSize=10&value=&filterValue={}&sort={"created_at":"DESC"}'>
                        {t("Project")}
                      </Link>
                      <Link href={`/projects/${reportMeta.project}`}>
                        {reportMeta.project_name}
                      </Link>
                      <Typography color="textPrimary" style={{ fontSize: 14 }}>
                        {reportMeta.name}
                      </Typography>
                      <Typography color="textPrimary" style={{ fontSize: 14 }}>
                        {reportMeta.name}
                      </Typography>
                    </Breadcrumbs>
                  </Box>
                  <Box display="flex" className={classes.projectDetails}>
                    <div className={classes.projectMeta}>
                      <Typography
                        component="h3"
                        variant="h4"
                        className={classes.projectMetaValue}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          onClick={handleClickOpen}
                          style={{
                            marginLeft: "auto",
                            display: "flex",
                          }}
                          endIcon={<NavigateNextIcon />}
                        >
                          {t("Samples")}
                        </Button>
                        <SamplesPopUp
                          id={reportMeta.project}
                          open={open}
                          projectData={projectData}
                          setProjectData={setProjectData}
                          refresh={refresh}
                          handleSelect={handleSelect}
                          handleClose={handleClickOpen}
                        />
                      </Typography>
                    </div>
                  </Box>
                  {appConf.entities.pipeline_classes[reportMeta.project_type][
                    reportMeta.pipeline_class
                  ] !== "Duo Analysis" && (
                    <div className={classes.projectMeta}>
                      <span className={classes.projectMetaKey}>
                        {t("Project type")}:
                      </span>
                      <Typography
                        component="h3"
                        variant="h6"
                        className={classes.projectMetaValue}
                      >
                        {
                          appConf.entities.project_types[
                            reportMeta.project_type
                          ]
                        }
                      </Typography>
                    </div>
                  )}
                  <div className={classes.projectMeta}>
                    <span className={classes.projectMetaKey}>
                      {t("Pipeline")}:
                    </span>
                    <Typography
                      component="h3"
                      variant="h6"
                      className={classes.projectMetaValue}
                    >
                      {
                        appConf.entities.pipeline_classes[
                          reportMeta.project_type
                        ][reportMeta.pipeline_class]
                      }
                    </Typography>
                  </div>
                  {appConf.entities.pipeline_classes[reportMeta.project_type][
                    reportMeta.pipeline_class
                  ] !== "Duo Analysis" && (
                    <div className={classes.projectMeta}>
                      <span className={classes.projectMetaKey}>
                        {t("Kit")}:
                      </span>
                      <Typography
                        component="h3"
                        variant="h6"
                        className={classes.projectMetaValue}
                      >
                        {appConf.entities.kits[reportMeta.kit]}
                      </Typography>
                    </div>
                  )}
                  <div className={classes.projectMeta}>
                    <span className={classes.projectMetaKey}>
                      {t("Schema Version")}:
                    </span>
                    <Typography
                      component="h3"
                      variant="h6"
                      className={classes.projectMetaValue}
                    >
                      V{reportMeta.version} / {reportMeta.project_type}
                    </Typography>
                  </div>
                  {appConf.entities.pipeline_classes[reportMeta.project_type][
                    reportMeta.pipeline_class
                  ] === "Duo Analysis" && (
                    <div className={classes.projectMeta}>
                      {" "}
                      <Typography
                        component="h3"
                        variant="h6"
                        className={classes.projectMetaValue}
                      >
                        <div>
                          <Typography
                            component="span"
                            className={classes.projectMetaKey}
                            style={{ fontWeight: "bold" }}
                          >
                            {`${t("Sample")} 1:`}
                          </Typography>{" "}
                          {reportMeta.name.split("-")[0]}
                        </div>
                        <div>
                          <Typography
                            component="span"
                            className={classes.projectMetaKey}
                            style={{ fontWeight: "bold" }}
                          >
                            {`${t("Sample")} 2:`}
                          </Typography>{" "}
                          {reportMeta.name.split("-")[1]}
                        </div>
                      </Typography>
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ marginBottom: "-8px", marginTop: "-8px" }}
              >
                <Grid item xs={12}>
                  <Tabs
                    className={classes.pageTabs}
                    value={analysisTabValue}
                    onChange={handleAnalysisTabChange}
                    variant="fullWidth"
                  >
                    {mainTabsOrdered.map((tabItem, idx) => (
                      <Tab
                        key={idx}
                        value={idx}
                        label={`${t(tabItem.label)} ${
                          tabItem.name === "variant_table" && totalRowCount
                            ? "(" + totalRowCount + ")"
                            : ""
                        }`}
                        id={`analysis-tab-${idx}`}
                        aria-controls={`analysis-tabpanel-${idx}`}
                      />
                    ))}
                  </Tabs>
                </Grid>
                <Grid item xs={12}>
                  {mainTabs["statistics"] && (
                    <TabPanel
                      value={analysisTabValue}
                      index={mainTabs["statistics"].order}
                    >
                      <Box p={4}>
                        <Typography component={"h4"} variant={"h4"}>
                          {t("Statistics")}
                        </Typography>
                        <Statistics
                          files={reportFiles}
                          version={reportMeta.version}
                          reportMeta={reportMeta}
                          userObject={userObject}
                          appConf={appConf}
                        />
                      </Box>
                    </TabPanel>
                  )}
                  {mainTabs["variant_table"] && (
                    <TabPanel
                      value={analysisTabValue}
                      index={mainTabs["variant_table"].order}
                    >
                      <Grid container spaci ng={2}>
                        <Grid item xs={12}>
                          <Box mt={2} display="flex">
                            <VariantTableLayoutEditor
                              appConf={appConf}
                              userObject={userObject}
                              reportFiles={reportFiles}
                              version={reportMeta.version}
                              schemaId={reportMeta.schema_id}
                              setHpoFilter={setHpoFilter}
                              colDetails={tableColDetails}
                              setColDetails={setTableColDetails}
                              open={openVariantTable}
                              setOpen={setOpenVariantTable}
                              defaultColObj={defaultColDetailsObject}
                              reportMeta={reportMeta}
                              visibleCols={visibleCols}
                              setTableFilterState={setTableFilterState}
                              setVisibleCols={setVisibleCols}
                              defaultColDetails={defaultColDetails}
                              setAdvanceFilter={setAdvanceFilter}
                              advanceFilter={advanceFilter}
                              tableVirtualized={<></>}
                            />
                            <SubmissionMenu
                              tableColDetails={tableColDetails}
                              setIsOrable={setIsOrable}
                              setTableColDetails={setTableColDetails}
                            />
                            <ReportGenerator
                              disabled={
                                analysisTabValue !==
                                reportSectionsConf[reportMeta.project_type]
                                  .tabs["variant_table"].order
                              }
                              reportId={id}
                              reportMeta={reportMeta}
                              checkedRows={checkedRows}
                              setCheckedRows={setCheckedRows}
                            />
                            <RowDataCopier
                              disabled={
                                analysisTabValue !==
                                reportSectionsConf[reportMeta.project_type]
                                  .tabs["variant_table"].order
                              }
                              checkedRows={checkedRows}
                              reportMeta={reportMeta}
                              visibleCols={visibleCols}
                              defaultColDetailsObject={defaultColDetailsObject}
                              totalRowCount={totalRowCount}
                            />
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{ marginTop: 8, marginBottom: 8 }}
                        >
                          <FilterStatusBox
                            tableType="variant"
                            open={openVariantTable}
                            setOpen={setOpenVariantTable}
                            setTableFilterTypes={setTableFilterTypes}
                            reportMeta={reportMeta}
                            setColDetails={setTableColDetails}
                            isOverflow={isOverflow}
                            setRefresh={setRefresh}
                            refresh={refresh}
                            projectData={projectData}
                            setIsOverflow={setIsOverflow}
                            processColDetails={processColDetails}
                            colDetails={tableColDetails}
                            defaultColDetailsObject={defaultColDetailsObject}
                            setVisibleCols={setVisibleCols}
                            defaultColDetails={defaultColDetails}
                            setTableSortState={setTableSortState}
                            tableFilterState={tableFilterState}
                            files={reportFiles}
                            setTableFilterState={setTableFilterState}
                            identifierColumn={identifierColumn}
                            SetIdentifierColumn={SetIdentifierColumn}
                            filteredRowCount={filteredRowCount}
                            igvLocation={igvLocation}
                            setAdvanceFilter={setAdvanceFilter}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <NewReportVirtualizedTable
                            tableColDetails={tableColDetails}
                            tableFilterState={tableFilterState}
                            tableSortState={tableSortState}
                            identifierColumn={identifierColumn}
                            SetIdentifierColumn={SetIdentifierColumn}
                            activeRowId={activeRowId}
                            setRowData={setRowData}
                            setActiveRowId={setActiveRowId}
                            isOrable={isOrable}
                            setCheckedRows={setCheckedRows}
                            tableFilterTypes={tableFilterTypes}
                            setDetail1Func={setDetail1Data}
                            setDetail2Func={setDetail2Data}
                            setDetail3Func={setDetail3Data}
                            setDetail4Func={setDetail4Data}
                            setDetail6Func={setDetail6Data}
                            setIgvLocation={setIgvLocation}
                            setDetailLinks={setDetailLinks}
                            setIsCalculated={setIsCalculated}
                            setTableFilterState={setTableFilterState}
                            setTableSortState={setTableSortState}
                            reportId={id}
                            hpo={hpoFilter}
                            setTableColDetails={setTableColDetails}
                            virtualTableHeight={virtualTableHeight}
                            defaultColDetailsObject={defaultColDetailsObject}
                            colDetails={tableColDetails}
                            setSpecifiedValues={setSpecifiedValues}
                            setUniprot={setUniprot}
                            setRsID={setRsID}
                            visibleCols={visibleCols}
                            isOverflow={isOverflow}
                            setVisibleCols={setVisibleCols}
                            setColDetails={setTableColDetails}
                            // rows={dataObjects}
                            checkedRows={checkedRows}
                            totalRowCount={totalRowCount}
                            setTotalRowCount={setTotalRowCount}
                            filteredRowCount={filteredRowCount}
                            setFilteredRowCount={setFilteredRowCount}
                            setRowAnnotations={setRowAnnotations}
                            reportMeta={reportMeta}
                          />
                        </Grid>
                      </Grid>
                      <DraggableHandle
                        virtualTableHeight={virtualTableHeight}
                        setVirtualTableHeight={setVirtualTableHeight}
                      />
                      {reportMeta.project_type !== "acnv" && (
                        <Grid container className={classes.detailsSection}>
                          <Grid item sm={12} md={detailLinks ? 10 : 12}>
                            <Grid
                              item
                              xs={12}
                              style={{ overflow: "auto" }}
                              className={classes.detailsHeader}
                            >
                              <Tabs
                                style={{
                                  overflowX: "auto",
                                  width: "fit-content",
                                }}
                                value={detailTabValue}
                                onChange={handleDetailTabChange}
                                variant="fullWidth"
                              >
                                {detailsTabsOrdered.map((tabItem, idx) => {
                                  return (
                                    <Tab
                                      value={idx}
                                      label={
                                        arrayIntersection(
                                          Object.keys(tableFilterState),
                                          tabItem.columns
                                        ).length ? (
                                          <Badge
                                            overlap="rectangular"
                                            color="secondary"
                                            variant="dot"
                                          >
                                            {t(tabItem.title)}
                                          </Badge>
                                        ) : (
                                          t(tabItem.title)
                                        )
                                      }
                                      id={`detailTab-${idx + 1}`}
                                      aria-controls={`detailTab-tabpanel-${
                                        idx + 1
                                      }`}
                                      key={idx}
                                    />
                                  );
                                })}
                                {reportMeta.annotable && (
                                  <Tab
                                    value={5}
                                    label={t("Comments and annotations")}
                                    id="detailLinks-tab-2"
                                    aria-controls="detailLinks-tabpanel-6"
                                  />
                                )}
                                {reportSectionsConf[reportMeta.project_type]
                                  .detailsTabs["igv"] && (
                                  <Tab
                                    value={6}
                                    label={t("Genome visualization")}
                                    id="detailLinks-tab-6"
                                    aria-controls="detailLinks-tabpanel-6"
                                  />
                                )}
                                {reportSectionsConf[reportMeta.project_type]
                                  .detailsTabs["uniprot"] &&
                                  uniprot && (
                                    <Tab
                                      value={7}
                                      label={t("uniprot")}
                                      id="detailLinks-tab-6"
                                      aria-controls="detailLinks-tabpanel-6"
                                    />
                                  )}
                                {reportSectionsConf[reportMeta.project_type]
                                  .detailsTabs["related_projects"] && (
                                  <Tab
                                    value={
                                      reportSectionsConf[
                                        reportMeta.project_type
                                      ].detailsTabs["related_projects"].order
                                    }
                                    label={t("Related Projects")}
                                    id="detailLinks-tab-6"
                                    aria-controls="detailLinks-tabpanel-6"
                                  />
                                )}
                              </Tabs>
                            </Grid>
                            <Box style={{ height: "inherit" }}>
                              {detailsTabsOrdered.map((tabItem, idx) => {
                                return (
                                  <TabPanel
                                    value={detailTabValue}
                                    index={idx}
                                    key={idx}
                                  >
                                    <Box p={4}>
                                      {tabItem?.kind === "tabbed_table" ? (
                                        <VariantDetailTable
                                          variantDetail={
                                            tabData[tabItem.key.toLowerCase()]
                                          }
                                        />
                                      ) : tabItem?.kind === "tabbed_grid" ? (
                                        <VariantDetailGrid
                                          filterState={tableFilterState}
                                          setColDetails={setTableColDetails}
                                          defaultColDetailsObject={
                                            defaultColDetailsObject
                                          }
                                          colDetails={tableColDetails}
                                          variantDetail={
                                            tabData[tabItem.key.toLowerCase()]
                                          }
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Box>
                                  </TabPanel>
                                );
                              })}
                              <TabPanel value={detailTabValue} index={5}>
                                <AnnotationsAndComments
                                  appConf={appConf}
                                  userObject={userObject}
                                  reportId={id}
                                  reportMeta={reportMeta}
                                  setReportMeta={setReportMeta}
                                  activeRowId={activeRowId}
                                  igvLocation={igvLocation}
                                  rowAnnotations={rowAnnotations}
                                  defaultColDetailsObject={
                                    defaultColDetailsObject
                                  }
                                />
                              </TabPanel>
                              {reportSectionsConf[reportMeta.project_type]
                                .detailsTabs["igv"] && (
                                <TabPanel
                                  value={detailTabValue}
                                  index={6}
                                  style={{ position: "relative" }}
                                >
                                  <IGVBrowser
                                    files={reportFiles}
                                    location={igvLocation}
                                    reportMeta={reportMeta}
                                  />
                                </TabPanel>
                              )}
                              {reportSectionsConf[reportMeta.project_type]
                                .detailsTabs["uniprot"] &&
                                uniprot && (
                                  <TabPanel
                                    value={detailTabValue}
                                    index={7}
                                    style={{ position: "relative" }}
                                  >
                                    <Protvista uniprot={uniprot} />
                                  </TabPanel>
                                )}
                              {reportSectionsConf[reportMeta.project_type]
                                .detailsTabs["related_projects"] &&
                                rsID && (
                                  <TabPanel
                                    value={detailTabValue}
                                    index={
                                      reportSectionsConf[
                                        reportMeta.project_type
                                      ].detailsTabs["related_projects"].order
                                    }
                                    style={{ position: "relative" }}
                                  >
                                    <RelatedProjects
                                      id={id}
                                      gene={rsID}
                                      column={
                                        reportMeta.version >= 12
                                          ? "main.rs_id"
                                          : "main.existing_variation"
                                      }
                                    />
                                  </TabPanel>
                                )}
                            </Box>
                          </Grid>
                          {detailLinks !== "loading" && detailLinks && (
                            <SidebarLinks
                              classes={classes}
                              defaultColDetailsObject={defaultColDetailsObject}
                              detailLinks={detailLinks}
                            />
                          )}
                        </Grid>
                      )}
                      {reportMeta.project_type === "acnv" &&
                        Boolean(totalRowCount) && (
                          <React.Fragment>
                            <Box style={{ background: "#FFF" }} padding={3}>
                              <Typography
                                variant="h4"
                                component="h4"
                                style={{ marginBottom: "16px" }}
                              >
                                {t("Genome visualization")}
                              </Typography>
                              <IGVBrowser
                                files={reportFiles}
                                location={igvLocation}
                                reportMeta={reportMeta}
                              />
                            </Box>
                            <Grid item xs={12}>
                              <Box p={4}>
                                <CNVPlots
                                  reportMeta={reportMeta}
                                  appConf={appConf}
                                  files={reportFiles}
                                />
                              </Box>
                            </Grid>
                          </React.Fragment>
                        )}
                    </TabPanel>
                  )}
                  {mainTabs["cnv"] && (
                    <TabPanel
                      value={analysisTabValue}
                      index={mainTabs["cnv"].order}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          {reportFiles["cnv"] && (
                            <CNVTable files={reportFiles} />
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Box p={4}>
                            <CNVPlots
                              reportMeta={reportMeta}
                              appConf={appConf}
                              files={reportFiles}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </TabPanel>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Page>
    );
  }
};

export const ReportPage = connect(mapStateToProps)(ConnectedReportPage);
