/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { findParedFile, getFileCellValues } from "./helpers";
import SaveIcon from "@material-ui/icons/Save";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import safeLogger from "src/services/safeLogger";
import { ArrowDown, ArrowUp } from "react-feather";
import { Cancel } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { PrimaryHistology, SingleGene } from ".";

const useStyles = makeStyles((theme) => ({
  hidden: {
    display: "none",
  },
  metaSummary: {
    marginBottom: "20px",
    display: "flex",
    "& p": {
      display: "inline-flex",
    },
  },
  alert: {},
}));

const CustomDropdown = (props) => {
  const {
    labelTitle,
    name,
    defaultValue,
    options,
    setOptions,
    updateFileMeta,
    isMutuallyExclusive,
    isRequired,
  } = props;
  const [value, setValue] = useState(defaultValue ? defaultValue : "");
  const { t } = useTranslation();

  const handleChange = (event) => {
    let newVal = event.target.value;
    if (
      (name.includes("pair_1") || name.includes("pair_2")) &&
      !newVal.annexes
    ) {
      if (typeof newVal !== "string") {
        newVal = { ...newVal, annexes: [] };
      }
    }

    if (isMutuallyExclusive) {
      const newOptions = { ...options };
      if (value && value !== newVal) newOptions[value] = false;
      newOptions[newVal] = true;
      setOptions(newOptions);
    }
    setValue(newVal);
    updateFileMeta(name, newVal);
  };

  return (
    <React.Fragment>
      <TextField
        style={{ minWidth: "100px" }}
        id={`select-${name}`}
        select
        name={name}
        value={typeof value === "string" ? value : value.name}
        onChange={handleChange}
        label={labelTitle}
        required={isRequired}
      >
        <MenuItem value={""}>-- {t("Please select an option")} --</MenuItem>
        {Object.keys(options).map((item, index) => (
          <MenuItem
            value={item}
            key={index}
            disabled={isMutuallyExclusive && options[item]}
          >
            {isMutuallyExclusive ? item : options[item]}
          </MenuItem>
        ))}
      </TextField>
    </React.Fragment>
  );
};

const CustomTextField = (props) => {
  const {
    rowIndex,
    inputIndex,
    sequencerType,
    label,
    name,
    value,
    updateFileMeta,
    formError,
    setFormError,
  } = props;
  const [inputValue, setInputValue] = useState(value);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const validators = {
    default: {
      pattern: "^[a-zA-Z0-9_-]+$",
      helper: t("alphanumeric values only"),
    },
    mgi: {
      flowcell: {
        pattern: "^[a-zA-Z0-9_-]{1,32}$",
        helper: t(
          "alphanumeric values only, minimum 1 and maximum 32 characters"
        ),
      },
      lane: {
        pattern: "^L[0-9]+$",
        helper: t("'L' followed by digits only"),
      },
      sample_name: {
        pattern: "^[a-zA-Z0-9_-]+$",
        helper: t("alphanumeric values only"),
        // pattern: "^S[a-zA-Z0-9_-]+$",
        // helper: t("'S' followed by alphanumeric values only"),
      },
    },
    illumina: {
      sample_name: {
        pattern: "^[a-zA-Z0-9_-]+$",
        helper: t("alphanumeric values only"),
      },
      sample_number: {
        pattern: "^S[0-9]+$",
        helper: t("'S' followed by digits only"),
      },
      lane: {
        pattern: "^L[0-9]+$",
        helper: t("'L' followed by digits only"),
      },
    },
  };

  const [helper, setHelper] = useState(
    sequencerType
      ? validators[sequencerType][name].helper
      : validators.default.helper
  );

  const handleErrorChange = (error) => {
    setHelper(
      error
        ? sequencerType
          ? validators[sequencerType][name].helper
          : validators.default.helper
        : ""
    );
    setError(error);
    formError[rowIndex][inputIndex] = error;
    setFormError(formError.slice(0));
  };

  useEffect(() => {
    if (formError.length && error === null) {
      const validator = new RegExp(
        sequencerType
          ? validators[sequencerType][name].pattern
          : validators.default.pattern
      );
      if (validator.test(value)) {
        handleErrorChange(false);
      } else {
        handleErrorChange(true);
      }
    }
  }, [formError]);

  useEffect(() => {
    let newVal = value;
    if (
      formError.length > rowIndex &&
      formError[rowIndex] &&
      newVal &&
      !inputValue
    ) {
      const re = new RegExp(/^[0-9]*$/);
      if (re.test(newVal)) {
        if (["lane", "sample_name", "sample_number"].includes(name)) {
          newVal = name[0].toUpperCase() + newVal;
          setInputValue();
          handleErrorChange(false);
        } else {
          setInputValue(newVal);
        }
      } else {
        setInputValue(newVal);
      }
      updateFileMeta(name, newVal);
    }
  }, [value, formError]);

  const changeHandler = (event) => {
    const newValue = event.target.value;
    const validator = new RegExp(
      sequencerType
        ? validators[sequencerType][name].pattern
        : validators.default.pattern
    );
    setInputValue(newValue);
    if (validator.test(newValue)) {
      handleErrorChange(false);
    } else {
      handleErrorChange(true);
    }
    updateFileMeta(name, newValue);
  };

  return (
    <TextField
      onChange={changeHandler}
      error={error}
      label={label}
      required
      name={name}
      value={inputValue}
      helperText={helper}
    />
  );
};

const ExomeFastqSampleFileTableRow = (props) => {
  const {
    headers,
    sequencerType,
    sampleKind,
    index,
    fileMeta,
    setFileMeta,
    fileNames,
    files,
    file,
    changed,
    uploadTabValue,
    formData,
    isMerged,
    setFileNames,
    formError,
    setFormError,
  } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState();

  useEffect(() => {
    if (isMerged) {
      let newFileMeta = fileMeta.slice(0);
      newFileMeta[index].calculate_frequency = true;
      newFileMeta[index].seperate = false;
      file["seperate"] = false;
      setFileMeta([...newFileMeta]);
    }
  }, [changed]);

  const updateFileMeta = (name, value) => {
    if (
      ["pair_1", "pair_2"].includes(name) &&
      ["1", "2", "3", "4"].includes(uploadTabValue)
    ) {
      let tmpFileSize;
      for (const file of files) {
        if (file.name === value) {
          if (file.byte_size) tmpFileSize = file.byte_size;
          if (file.size) tmpFileSize = file.size;
        }
      }
      file[name] = { name: value, annexes: [], byte_size: tmpFileSize };
    } else {
      file[name] = value;
    }
    setFileMeta((prev) => [...prev]);
  };

  const handleDelete = (newFile) => {
    setFormError([...formError, {}]);
    file["merged"] = file["merged"].filter(
      (val) => newFile.flowcell !== val.flowcell
    );
    setFileMeta((prev) => [...prev, newFile]);
  };

  return (
    <>
      <TableRow>
        {["1", "2", "3", "4"].includes(uploadTabValue) && isMerged && (
          <TableCell>
            {file["merged"] && file["merged"]?.length > 0 ? (
              <IconButton onClick={() => setOpen((prev) => !prev)}>
                {open ? <ArrowDown /> : <ArrowUp />}
              </IconButton>
            ) : (
              <Checkbox
                checked={fileMeta[index]["seperate"]}
                name="seperate"
                onChange={(e) =>
                  updateFileMeta(e.target.name, e.target.checked)
                }
              />
            )}
          </TableCell>
        )}
        {Object.keys(headers).map((header, i) => (
          <TableCell key={i}>
            <CustomTextField
              rowIndex={index}
              inputIndex={i}
              sequencerType={sequencerType}
              label={headers[header]}
              name={header}
              value={file[header] ? file[header] : ""}
              updateFileMeta={updateFileMeta}
              formError={formError}
              setFormError={setFormError}
            />
          </TableCell>
        ))}
        {sampleKind === "paired_end" ? (
          <>
            <TableCell>
              <CustomDropdown
                defaultValue={file["pair_1"] ? file["pair_1"] : ""}
                options={fileNames}
                setOptions={setFileNames}
                name={"pair_1"}
                labelTitle={t("Pair 1")}
                updateFileMeta={updateFileMeta}
                isMutuallyExclusive={true}
                isRequired={true}
              />
            </TableCell>
            <TableCell>
              <CustomDropdown
                defaultValue={file["pair_2"] ? file["pair_2"] : ""}
                options={fileNames}
                setOptions={setFileNames}
                name={"pair_2"}
                labelTitle={t("Pair 2")}
                updateFileMeta={updateFileMeta}
                isMutuallyExclusive={true}
                isRequired={true}
              />
            </TableCell>
          </>
        ) : (
          <TableCell>{file["filename"] ? file["filename"] : ""}</TableCell>
        )}
        <SingleGene
          data={fileMeta[index]}
          single_gene={formData.single_gene}
          pipeline_class={formData.pipeline_class}
          handleChange={updateFileMeta}
        />
        <PrimaryHistology
          data={fileMeta[index]}
          pipeline_class={formData.pipeline_class}
          handleChange={updateFileMeta}
        />
        <TableCell>
          <FormControlLabel
            onChange={(event, checked) => {
              updateFileMeta(event.target.name, checked);
            }}
            checked={file.calculate_frequency ?? true}
            control={<Checkbox name="calculate_frequency" />}
          />
        </TableCell>
      </TableRow>
      {file["merged"] && file["merged"].length > 0 && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={
              Object.keys(headers).length +
              (sampleKind === "paired_end" ? 4 : 3)
            }
          >
            <Collapse in={open} timeout={1} unmountOnExit>
              <Table>
                <TableHead style={{ height: 0 }}>
                  <TableRow>
                    <TableCell>{t("Flowcell")}</TableCell>
                    <TableCell>{t("Flowcell")}</TableCell>
                    <TableCell>{t("Sample Name")}</TableCell>
                    <TableCell>{t("Pair 1")}</TableCell>
                    <TableCell>{t("Pair 2")}</TableCell>
                    <TableCell>{t("Cancel")}</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {file["merged"].map((file, index) => {
                    return (
                      <TableRow>
                        <TableCell>{file.flowcell}</TableCell>
                        <TableCell>{file.lane}</TableCell>
                        <TableCell>{file.sample_name}</TableCell>
                        <TableCell>{file.pair_1.name}</TableCell>
                        <TableCell>{file.pair_2.name}</TableCell>
                        <TableCell>
                          <IconButton
                            color="secondary"
                            onClick={(e) => handleDelete(file)}
                          >
                            <Cancel />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export const ExomeFastqSampleFilesTable = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [formError, setFormError] = useState([]);
  const {
    appConf,
    formData,
    files,
    fileMeta,
    uploadTabValue,
    setFileMeta,
    phase,
    setPhase,
  } = props;

  const [headers, setHeaders] = useState(null);
  const [fileNames, setFileNames] = useState(null);
  const [changed, setChanged] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const mgiTableHeaders = {
    flowcell: "Flowcell",
    lane: "Lane",
    sample_name: "Sample",
  };

  const illuminaTableHeaders = {
    sample_name: "Sample Name",
    sample_number: "Sample Number",
    lane: "Lane",
  };

  useEffect(() => {
    if (fileMeta.length && formError.length !== fileMeta.length) {
      safeLogger(fileMeta);
      for (let i = 0; i < fileMeta.length; i++) {
        formError.push({});
      }
      setFormError(formError.slice(0));
    }
  }, [fileMeta]);

  useEffect(() => {
    if (headers) {
      let tmpFileObjects = [];

      if (formData.sample_kind === "paired_end") {
        let pairedFiles = [];

        for (let i = 0; i < files.length; i++) {
          if (!pairedFiles.includes(files[i].name)) {
            let fileObj = {};
            const cellValues = getFileCellValues(files[i].name);
            if (cellValues) {
              Object.keys(headers).map(
                (headItem, index) => (fileObj[headItem] = cellValues[index])
              );
            }
            const pairFileName = findParedFile(files[i].name, files);
            if (pairFileName) {
              fileObj["pair_1"] =
                uploadTabValue === "1"
                  ? {
                      name: files[i].name,
                      flowIdentifier: files[i]["flowIdentifier"],
                      byte_size: files[i]["size"],
                      annexes: [],
                    }
                  : {
                      name: files[i].name,
                      flowIdentifier: files[i]["flowIdentifier"],
                      byte_size: files[i]["size"],
                      annexes: [],
                    };
              fileObj["pair_2"] =
                uploadTabValue === "1"
                  ? {
                      name: pairFileName.name,
                      flowIdentifier: pairFileName.flowIdentifier,
                      byte_size: pairFileName["size"],
                      annexes: [],
                    }
                  : {
                      name: pairFileName.name,
                      flowIdentifier: pairFileName.flowIdentifier,
                      byte_size: pairFileName["size"],
                      annexes: [],
                    };
              fileObj["flowIdentifier"] = files[i]["flowIdentifier"];
              fileObj["calculate_frequency"] = true;
              fileObj["merged"] = [];
              tmpFileObjects.push(fileObj);
              pairedFiles.push(files[i].name);
              pairedFiles.push(pairFileName.name);
              fileNames[files[i].name] = true;
              fileNames[pairFileName.name] = true;
            }
          }
        }

        if (pairedFiles.length !== files.length) {
          for (let i = 0; i < (files.length - pairedFiles.length) / 2; i++) {
            tmpFileObjects.push({});
          }
        }

        setFileMeta(tmpFileObjects);
      } else {
        for (let i = 0; i < files.length; i++) {
          let fileObj = {};
          const cellValues = getFileCellValues(files[i].name);
          if (cellValues) {
            Object.keys(headers).map(
              (headItem, index) => (fileObj[headItem] = cellValues[index])
            );
          }
          fileObj["byte_size"] = files[i].size;
          fileObj["filename"] = files[i].name;
          tmpFileObjects.push(fileObj);
        }
        setFileMeta(tmpFileObjects);
      }
    }
  }, [headers]);

  useEffect(() => {
    if (phase === "sampleMeta") {
      if (!headers) {
        setHeaders(
          formData.sequencer === "mgi" ? mgiTableHeaders : illuminaTableHeaders
        );
      }

      let tmpFileNames = {};
      for (let i = 0; i < files.length; i++) {
        tmpFileNames[files[i].name] = false;
      }
      setFileNames(tmpFileNames);
    }
  }, [phase]);

  const isInputFormatValid = () => {
    for (let i = 0; i < formError.length; i++) {
      const formErrorKeys = Object.keys(formError[i]);
      for (let j = 0; j < formErrorKeys.length; j++) {
        if (formError[i][formErrorKeys[j]]) return false;
      }
    }

    return true;
  };

  const isFileConfigUnique = () => {
    const configValues = [];
    for (let i = 0; i < fileMeta.length; i++) {
      let fileConfig = null;
      if (formData.sequencer === "mgi") {
        fileConfig =
          fileMeta[i].flowcell + fileMeta[i].lane + fileMeta[i].sample_name;
      } else {
        fileConfig =
          fileMeta[i].sample_name +
          fileMeta[i].sample_number +
          fileMeta[i].lane;
      }
      if (!configValues.includes(fileConfig)) {
        configValues.push(fileConfig);
      } else {
        return false;
      }
    }

    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isInputFormatValid()) {
      if (isFileConfigUnique()) {
        setPhase("credit_calculate");
      } else {
        enqueueSnackbar(
          t(
            "form_conf_not_unique_message",
            "Form configuration is not unique. Please check and try again."
          ),
          {
            variant: "error",
          }
        );
      }
    } else {
      enqueueSnackbar(
        t(
          "form_input_not_valid_message",
          "Form input are not valid. Please check and try again."
        ),
        {
          variant: "error",
        }
      );
    }
  };

  const handleMerge = async () => {
    setLoading(false);
    let firstID = undefined;
    let firstSample = undefined;
    const mergeSamples = [];
    let newFileMeta = fileMeta;
    for (let i = 0; i < newFileMeta.length; i++) {
      if (newFileMeta[i].seperate) {
        newFileMeta[i]["seperate"] = false;
        if (!firstSample && !firstID) {
          firstSample = newFileMeta[i];
          firstID = i;
        } else {
          mergeSamples.push(newFileMeta[i]);
          delete newFileMeta[i];
        }
      } else {
        newFileMeta[i]["seperate"] = false;
      }
    }
    newFileMeta = await newFileMeta.map((val) => {
      val.seperate = false;
      return val;
    });
    if (firstSample) {
      newFileMeta[firstID]["merged"] = mergeSamples;
      // newFileMeta = newFileMeta.map(val=> value)
      setFormError([
        ...newFileMeta.filter((value) => Boolean(value)).map((val) => ({})),
      ]);
      setChanged((prev) => !prev);
      setFileMeta([
        ...newFileMeta
          .filter((value) => Boolean(value))
          .map((val) => {
            val.seperate = false;
            return val;
          }),
      ]);
      setOpen(false);
    }
    setLoading(true);
  };

  return (
    <Grid item xs={12} className={clsx({ [classes.hidden]: phase === "init" })}>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth="xl"
      >
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                {headers &&
                  Object.values(headers).map((item, index) => (
                    <TableCell key={index}>{item}</TableCell>
                  ))}
                {formData.sample_kind === "paired_end" ? (
                  <>
                    <TableCell>{t("Pair 1")}</TableCell>
                    <TableCell>{t("Pair 2")}</TableCell>
                  </>
                ) : (
                  <TableCell>{t("File Name")}</TableCell>
                )}
                {formData.pipeline_class &&
                  (formData.pipeline_class === "germline" ||
                    formData.pipeline_class.includes("germline")) &&
                  formData.single_gene &&
                  (formData.single_gene === "amplification" ||
                    formData.single_gene.includes("amplification")) && (
                    <TableCell>{t("Genes")}</TableCell>
                  )}
                <TableCell>{t("Variant Frequency Calculation")}</TableCell>
                {formData.pipeline_class &&
                  (formData.pipeline_class === "somatic" ||
                    formData.pipeline_class.includes("somatic")) && (
                    <>
                      <TableCell>{t("Tissues")}</TableCell>
                      <TableCell>{t("Histology")}</TableCell>
                    </>
                  )}
              </TableRow>
            </TableHead>
            <TableBody>
              {fileMeta.map((file, index) => {
                return (
                  file?.seperate && (
                    <ExomeFastqSampleFileTableRow
                      key={index}
                      isMerged={false}
                      uploadTabValue={uploadTabValue}
                      files={files}
                      index={index}
                      headers={headers}
                      file={file}
                      sequencerType={formData.sequencer}
                      sampleKind={formData.sample_kind}
                      fileMeta={fileMeta}
                      setFileMeta={setFileMeta}
                      formData={formData}
                      fileNames={fileNames}
                      setFileNames={setFileNames}
                      formError={formError}
                      setFormError={setFormError}
                    />
                  )
                );
              })}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMerge} color="primary" variant="contained">
            {t("Merge")}
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              setOpen(false);
            }}
            variant="contained"
          >
            {t("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
      <Box className={classes.metaSummary}>
        <Typography>
          <span style={{ color: "#5e5e5e" }}>{t("Title")}:</span>
          <span style={{ marginLeft: "4px", marginRight: "20px" }}>
            {formData.name}
          </span>
        </Typography>
        <Typography>
          <span style={{ color: "#5e5e5e" }}>{t("Type")}:</span>
          <span style={{ marginLeft: "4px", marginRight: "20px" }}>
            {formData.sample_kind.replace("_", "-")}
          </span>
        </Typography>
        <Typography>
          <span style={{ color: "#5e5e5e" }}>{t("Kit")}:</span>
          <span style={{ marginLeft: "4px", marginRight: "20px" }}>
            {appConf.entities.kits[formData.kit]}
          </span>
        </Typography>
        <Typography>
          <span style={{ color: "#5e5e5e" }}>{t("Sequencer")}:</span>
          <span style={{ marginLeft: "4px", marginRight: "20px" }}>
            {appConf.entities.sequencers[formData.sequencer]}
          </span>
        </Typography>
        <Typography>
          <span style={{ color: "#5e5e5e" }}>{t("Pipeline")}:</span>
          <span style={{ marginLeft: "4px", marginRight: "20px" }}>
            {
              appConf.entities.pipeline_classes["exome_fastq"][
                formData.pipeline_class
              ]
            }
          </span>
        </Typography>
        {["1", "2", "3", "4"].includes(uploadTabValue) && (
          <Typography style={{ margin: "auto" }}>
            <span style={{ color: "#5e5e5e" }}>{t("Merge Samples")}:</span>
            <span style={{ marginLeft: "4px", marginRight: "20px" }}>
              <Button
                onClick={() => setOpen(true)}
                color="primary"
                variant="outlined"
              >
                {t("Merge")}
              </Button>
            </span>
          </Typography>
        )}
      </Box>
      {fileMeta.length ? (
        <form onSubmit={handleSubmit}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="1" />
                {/* {["1", "3", "4", "2"].includes(uploadTabValue) && <TableCell />} */}
                {headers &&
                  Object.values(headers).map((item, index) => (
                    <TableCell width="100px" key={index}>
                      {item}
                    </TableCell>
                  ))}
                {formData.sample_kind === "paired_end" ? (
                  <>
                    <TableCell width="100px">{t("Pair 1")}</TableCell>
                    <TableCell width="100px">{t("Pair 2")}</TableCell>
                  </>
                ) : (
                  <TableCell width="100px">{t("File Name")}</TableCell>
                )}
                {formData.pipeline_class &&
                  (formData.pipeline_class === "germline" ||
                    formData.pipeline_class.includes("germline")) &&
                  formData.single_gene &&
                  (formData.single_gene === "amplification" ||
                    formData.single_gene.includes("amplification")) && (
                    <TableCell width="400px">{t("Genes")}</TableCell>
                  )}
                {formData.pipeline_class &&
                  (formData.pipeline_class === "somatic" ||
                    formData.pipeline_class.includes("somatic")) && (
                    <>
                      <TableCell width="100px">{t("Tissues")}</TableCell>
                      <TableCell width="100px">{t("Histology")}</TableCell>
                    </>
                  )}
                <TableCell width="10px">
                  {t("Variant Frequency Calculation")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading &&
                fileMeta.map((file, index) => {
                  return (
                    <ExomeFastqSampleFileTableRow
                      key={index}
                      index={index}
                      changed={changed}
                      files={files}
                      isMerged={true}
                      headers={headers}
                      sequencerType={formData.sequencer}
                      sampleKind={formData.sample_kind}
                      uploadTabValue={uploadTabValue}
                      file={file}
                      formData={formData}
                      fileMeta={fileMeta}
                      setFileMeta={setFileMeta}
                      fileNames={fileNames}
                      setFileNames={setFileNames}
                      formError={formError}
                      setFormError={setFormError}
                    />
                  );
                })}
            </TableBody>
          </Table>
          <Box display="flex" mt={5}>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<NavigateBeforeIcon />}
              onClick={() => setPhase("init")}
            >
              {t("Back")}
            </Button>
            <Button
              type="submit"
              style={{ marginLeft: "auto" }}
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
            >
              {t("Submit")}
            </Button>
          </Box>
        </form>
      ) : (
        ""
      )}
    </Grid>
  );
};

const ExomeVcfSampleFileTableRow = (props) => {
  const {
    headers,
    index,
    fileMeta,
    setFileMeta,
    formError,
    setFormError,
    formData,
  } = props;

  const updateFileMeta = (name, value) => {
    let newFileMeta = fileMeta.slice(0);
    newFileMeta[index][name] = value;
    setFileMeta(newFileMeta);
  };

  return (
    <TableRow>
      {Object.keys(headers).map((header, i) => (
        <TableCell key={i}>
          <CustomTextField
            rowIndex={index}
            inputIndex={i}
            label={headers[header]}
            name={header}
            value={fileMeta[index][header] ? fileMeta[index][header] : ""}
            updateFileMeta={updateFileMeta}
            formError={formError}
            setFormError={setFormError}
          />
        </TableCell>
      ))}

      <TableCell>
        {fileMeta[index]["filename"] ? fileMeta[index]["filename"] : ""}
      </TableCell>

      <SingleGene
        data={fileMeta[index]}
        single_gene={formData.single_gene}
        pipeline_class={formData.pipeline_class}
        handleChange={updateFileMeta}
      />

      <PrimaryHistology
        data={fileMeta[index]}
        pipeline_class={formData.pipeline_class}
        handleChange={updateFileMeta}
      />

      <TableCell>
        <FormControlLabel
          onChange={(event, checked) => {
            updateFileMeta(event.target.name, checked);
          }}
          checked={fileMeta[index].calculate_frequency ?? true}
          control={<Checkbox name="calculate_frequency" />}
        />
      </TableCell>
    </TableRow>
  );
};

export const ExomeVcfSampleFilesTable = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [formError, setFormError] = useState([]);
  const { formData, files, fileMeta, setFileMeta, phase, setPhase } = props;
  const [headers, setHeaders] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (fileMeta.length && formError.length !== fileMeta.length) {
      safeLogger("setting form error");
      for (let i = 0; i < fileMeta.length; i++) {
        formError.push({});
      }
      setFormError(formError.slice(0));
    }
  }, [fileMeta]);

  useEffect(() => {
    if (headers) {
      let tmpFileObjects = [];

      for (let i = 0; i < files.length; i++) {
        let fileObj = {};
        const cellValues = getFileCellValues(files[i].name);
        if (cellValues) {
          Object.keys(headers).map(
            (headItem, index) => (fileObj[headItem] = cellValues[index])
          );
        }
        fileObj["byte_size"] = files[i].size;
        fileObj["filename"] = files[i].name;
        // default values for lane
        fileObj["lane"] = "L01";
        fileObj["calculate_frequency"] = true;

        tmpFileObjects.push(fileObj);
      }

      setFileMeta(tmpFileObjects);
    }
  }, [headers]);

  useEffect(() => {
    if (phase === "sampleMeta") {
      if (!headers) {
        setHeaders({ sample_name: "Sample name" });
      }
    }
  }, [phase]);

  const isInputFormatValid = () => {
    for (let i = 0; i < formError.length; i++) {
      const formErrorKeys = Object.keys(formError[i]);
      for (let j = 0; j < formErrorKeys.length; j++) {
        if (formError[i][formErrorKeys[j]]) return false;
      }
    }

    return true;
  };

  const isFileConfigUnique = () => {
    const configValues = [];
    for (let i = 0; i < fileMeta.length; i++) {
      if (!configValues.includes(fileMeta[i].sample_name)) {
        configValues.push(fileMeta[i].sample_name);
      } else {
        return false;
      }
    }

    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isInputFormatValid()) {
      if (isFileConfigUnique()) {
        setPhase("credit_calculate");
      } else {
        enqueueSnackbar(
          t(
            "form_conf_not_unique_message",
            "Form configuration is not unique. Please check and try again."
          ),
          {
            variant: "error",
          }
        );
      }
    } else {
      enqueueSnackbar(
        t(
          "form_input_not_valid_message",
          "Form input are not valid. Please check and try again."
        ),
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <Grid item xs={12} className={clsx({ [classes.hidden]: phase === "init" })}>
      <Box className={classes.metaSummary}>
        <Typography>
          <span style={{ color: "#5e5e5e" }}>{t("Title")}:</span>
          <span style={{ marginLeft: "4px", marginRight: "20px" }}>
            {formData.name}
          </span>
        </Typography>
      </Box>
      {fileMeta.length ? (
        <form onSubmit={handleSubmit}>
          <Table>
            <TableHead>
              <TableRow>
                {headers &&
                  Object.values(headers).map((item, index) => (
                    <TableCell key={index}>{item}</TableCell>
                  ))}
                <TableCell>{t("File name")}</TableCell>
                {formData.pipeline_class &&
                  (formData.pipeline_class === "somatic" ||
                    formData.pipeline_class.includes("somatic")) && (
                    <>
                      <TableCell>{t("Tissues")}</TableCell>
                      <TableCell>{t("Histology")}</TableCell>
                    </>
                  )}
                <TableCell>{t("Variant Frequency Calculation")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fileMeta.map((file, index) => {
                return (
                  <ExomeVcfSampleFileTableRow
                    key={index}
                    index={index}
                    headers={headers}
                    formData={formData}
                    fileMeta={fileMeta}
                    setFileMeta={setFileMeta}
                    formError={formError}
                    setFormError={setFormError}
                  />
                );
              })}
            </TableBody>
          </Table>
          <Box display="flex" mt={5}>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<NavigateBeforeIcon />}
              onClick={() => setPhase("init")}
            >
              {t("Back")}
            </Button>
            <Button
              type="submit"
              style={{ marginLeft: "auto" }}
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
            >
              {t("Submit")}
            </Button>
          </Box>
        </form>
      ) : (
        ""
      )}
    </Grid>
  );
};

export const ExomeBAMSampleFilesTable = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [formError, setFormError] = useState([]);
  const { formData, files, fileMeta, setFileMeta, phase, setPhase } = props;
  const [headers, setHeaders] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (fileMeta.length && formError.length !== fileMeta.length) {
      safeLogger(fileMeta);
      for (let i = 0; i < fileMeta.length; i++) {
        formError.push({});
      }
      setFormError(formError.slice(0));
    }
  }, [fileMeta]);

  useEffect(() => {
    if (headers) {
      let tmpFileObjects = [];

      for (let i = 0; i < files.length; i++) {
        let fileObj = {};
        const cellValues = getFileCellValues(files[i].name);
        if (cellValues) {
          Object.keys(headers).map(
            (headItem, index) => (fileObj[headItem] = cellValues[index])
          );
        }
        fileObj["byte_size"] = files[i].size;
        fileObj["filename"] = files[i].name;
        // default values for lane
        fileObj["lane"] = "L01";
        fileObj["calculate_frequency"] = true;

        tmpFileObjects.push(fileObj);
      }

      setFileMeta(tmpFileObjects);
    }
  }, [headers]);

  useEffect(() => {
    if (phase === "sampleMeta") {
      if (!headers) {
        setHeaders({ sample_name: "Sample name" });
      }
    }
  }, [phase]);

  const isInputFormatValid = () => {
    for (let i = 0; i < formError.length; i++) {
      const formErrorKeys = Object.keys(formError[i]);
      for (let j = 0; j < formErrorKeys.length; j++) {
        if (formError[i][formErrorKeys[j]]) return false;
      }
    }

    return true;
  };

  const isFileConfigUnique = () => {
    const configValues = [];
    for (let i = 0; i < fileMeta.length; i++) {
      if (!configValues.includes(fileMeta[i].sample_name)) {
        configValues.push(fileMeta[i].sample_name);
      } else {
        return false;
      }
    }

    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isInputFormatValid()) {
      if (isFileConfigUnique()) {
        setPhase("credit_calculate");
      } else {
        enqueueSnackbar(
          t(
            "form_conf_not_unique_message",
            "Form configuration is not unique. Please check and try again."
          ),
          {
            variant: "error",
          }
        );
      }
    } else {
      enqueueSnackbar(
        t(
          "form_input_not_valid_message",
          "Form input are not valid. Please check and try again."
        ),
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <Grid item xs={12} className={clsx({ [classes.hidden]: phase === "init" })}>
      <Box className={classes.metaSummary}>
        <Typography>
          <span style={{ color: "#5e5e5e" }}>{t("Title")}:</span>
          <span style={{ marginLeft: "4px", marginRight: "20px" }}>
            {formData.name}
          </span>
        </Typography>
      </Box>
      {fileMeta.length ? (
        <form onSubmit={handleSubmit}>
          <Table>
            <TableHead>
              <TableRow>
                {headers &&
                  Object.values(headers).map((item, index) => (
                    <TableCell key={index}>{item}</TableCell>
                  ))}
                <TableCell>{t("File name")}</TableCell>
                {formData.pipeline_class &&
                  (formData.pipeline_class === "germline" ||
                    formData.pipeline_class.includes("germline")) &&
                  formData.single_gene &&
                  (formData.single_gene === "amplification" ||
                    formData.single_gene.includes("amplification")) && (
                    <TableCell width="400px">{t("Genes")}</TableCell>
                  )}
                {formData.pipeline_class &&
                  (formData.pipeline_class === "somatic" ||
                    formData.pipeline_class.includes("somatic")) && (
                    <>
                      <TableCell>{t("Tissues")}</TableCell>
                      <TableCell>{t("Histology")}</TableCell>
                    </>
                  )}
                <TableCell>{t("Variant Frequency Calculation")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fileMeta.map((file, index) => {
                return (
                  <ExomeVcfSampleFileTableRow
                    key={index}
                    index={index}
                    headers={headers}
                    formData={formData}
                    fileMeta={fileMeta}
                    setFileMeta={setFileMeta}
                    formError={formError}
                    setFormError={setFormError}
                  />
                );
              })}
            </TableBody>
          </Table>
          <Box display="flex" mt={5}>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<NavigateBeforeIcon />}
              onClick={() => setPhase("init")}
            >
              {t("Back")}
            </Button>
            <Button
              type="submit"
              style={{ marginLeft: "auto" }}
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
            >
              {t("Submit")}
            </Button>
          </Box>
        </form>
      ) : (
        ""
      )}
    </Grid>
  );
};

const TrioSampleFileTableRow = (props) => {
  const {
    headers,
    sequencerType,
    index,
    fileMeta,
    setFileMeta,
    fileNames,
    setFileNames,
    formError,
    setFormError,
    roles,
    setRoles,
  } = props;

  const { t } = useTranslation();

  const updateFileMeta = (name, value) => {
    let newFileMeta = fileMeta.slice(0);
    newFileMeta[index][name] = value;
    setFileMeta(newFileMeta);
  };

  return (
    <TableRow>
      {Object.keys(headers).map((header, i) => (
        <TableCell key={i}>
          <CustomTextField
            rowIndex={index}
            inputIndex={i}
            sequencerType={sequencerType}
            label={headers[header]}
            name={header}
            value={fileMeta[index][header] ? fileMeta[index][header] : ""}
            updateFileMeta={updateFileMeta}
            formError={formError}
            setFormError={setFormError}
          />
        </TableCell>
      ))}
      <TableCell>
        <CustomDropdown
          defaultValue={
            fileMeta[index]["pair_1"] ? fileMeta[index]["pair_1"].name : ""
          }
          options={fileNames}
          setOptions={setFileNames}
          name={"pair_1"}
          labelTitle={t("Pair 1")}
          updateFileMeta={updateFileMeta}
          isMutuallyExclusive={true}
          isRequired={true}
        />
      </TableCell>
      <TableCell>
        <CustomDropdown
          defaultValue={
            fileMeta[index]["pair_2"] ? fileMeta[index]["pair_2"].name : ""
          }
          options={fileNames}
          setOptions={setFileNames}
          name={"pair_2"}
          labelTitle={t("Pair 2")}
          updateFileMeta={updateFileMeta}
          isMutuallyExclusive={true}
          isRequired={true}
        />
      </TableCell>
      <TableCell>
        <CustomDropdown
          defaultValue={
            fileMeta[index]["role"] ? fileMeta[index]["pair_2"] : ""
          }
          options={roles}
          setOptions={setRoles}
          name={"role"}
          labelTitle={t("Role")}
          updateFileMeta={updateFileMeta}
          isMutuallyExclusive={true}
          isRequired={true}
        />
      </TableCell>
      <TableCell>
        <FormControlLabel
          onChange={(event, checked) => {
            updateFileMeta(event.target.name, checked);
          }}
          checked={fileMeta[index].calculate_frequency ?? true}
          control={<Checkbox name="calculate_frequency" />}
        />
      </TableCell>
    </TableRow>
  );
};

export const TrioSampleFilesTable = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [formError, setFormError] = useState([]);
  const { appConf, formData, files, fileMeta, setFileMeta, phase, setPhase } =
    props;

  const [headers, setHeaders] = useState(undefined);
  const [fileNames, setFileNames] = useState(null);
  const [roles, setRoles] = useState({
    mother: false,
    father: false,
    child: false,
  });
  const { t } = useTranslation();

  const mgiTableHeaders = {
    flowcell: "Flowcell",
    lane: "Lane",
    sample_name: "Sample",
  };

  const illuminaTableHeaders = {
    sample_name: "Sample Name",
    sample_number: "Sample Number",
    lane: "Lane",
  };

  useEffect(() => {
    if (fileMeta.length && formError.length !== fileMeta.length) {
      safeLogger(fileMeta);
      for (let i = 0; i < fileMeta.length; i++) {
        formError.push({});
      }
      setFormError(formError.slice(0));
    }
  }, [fileMeta]);

  useEffect(() => {
    if (headers) {
      let tmpFileObjects = [];
      let pairedFiles = [];

      for (let i = 0; i < files.length; i++) {
        if (!pairedFiles.includes(files[i].name)) {
          let fileObj = {};
          const cellValues = getFileCellValues(files[i].name);

          if (cellValues) {
            Object.keys(headers).map(
              (headItem, index) => (fileObj[headItem] = cellValues[index])
            );
          }
          const pairFileName = findParedFile(files[i].name, files);
          if (pairFileName) {
            fileObj["pair_1"] = {
              name: files[i].name,
              byte_size: files[i].size,
              annexes: [],
            };
            fileObj["pair_2"] = {
              name: pairFileName.name,
              byte_size: pairFileName.size,
              annexes: [],
            };
            fileObj["calculate_frequency"] = true;
            tmpFileObjects.push(fileObj);
            pairedFiles.push(files[i].name);
            pairedFiles.push(pairFileName.name);
            fileNames[files[i].name] = true;
            fileNames[pairFileName] = true;
          }
        }
      }

      if (pairedFiles.length !== files.length) {
        for (let i = 0; i < (files.length - pairedFiles.length) / 2; i++) {
          tmpFileObjects.push({});
        }
      }

      setFileMeta(tmpFileObjects);
    }
  }, [headers]);

  useEffect(() => {
    if (phase === "sampleMeta") {
      if (!headers) {
        setHeaders(
          formData.sequencer === "mgi" ? mgiTableHeaders : illuminaTableHeaders
        );
      }

      let tmpFileNames = {};
      for (let i = 0; i < files.length; i++) {
        tmpFileNames[files[i].name] = false;
      }
      setFileNames(tmpFileNames);
    }
  }, [phase]);

  const isInputFormatValid = () => {
    for (let i = 0; i < formError.length; i++) {
      const formErrorKeys = Object.keys(formError[i]);
      for (let j = 0; j < formErrorKeys.length; j++) {
        if (formError[i][formErrorKeys[j]]) return false;
      }
    }

    return true;
  };

  const isFileConfigUnique = () => {
    const configValues = [];
    for (let i = 0; i < fileMeta.length; i++) {
      let fileConfig = null;
      if (formData.sequencer === "mgi") {
        fileConfig =
          fileMeta[i].flowcell + fileMeta[i].lane + fileMeta[i].sample_name;
      } else {
        fileConfig =
          fileMeta[i].sample_name +
          fileMeta[i].sample_number +
          fileMeta[i].lane;
      }
      if (!configValues.includes(fileConfig)) {
        configValues.push(fileConfig);
      } else {
        return false;
      }
    }

    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isInputFormatValid()) {
      if (isFileConfigUnique()) {
        setPhase("credit_calculate");
      } else {
        enqueueSnackbar(
          t(
            "form_conf_not_unique_message",
            "Form configuration is not unique. Please check and try again."
          ),
          {
            variant: "error",
          }
        );
      }
    } else {
      enqueueSnackbar(
        t(
          "form_input_not_valid_message",
          "Form input are not valid. Please check and try again."
        ),
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <Grid item xs={12} className={clsx({ [classes.hidden]: phase === "init" })}>
      <Box className={classes.metaSummary}>
        <Typography>
          <span style={{ color: "#5e5e5e" }}>{t("Title")}:</span>
          <span style={{ marginLeft: "4px", marginRight: "20px" }}>
            {formData.name}
          </span>
        </Typography>
        <Typography>
          <span style={{ color: "#5e5e5e" }}>{t("Kit")}:</span>
          <span style={{ marginLeft: "4px", marginRight: "20px" }}>
            {appConf.entities.kits[formData.kit]}
          </span>
        </Typography>
        <Typography>
          <span style={{ color: "#5e5e5e" }}>{t("Sequencer")}:</span>
          <span style={{ marginLeft: "4px", marginRight: "20px" }}>
            {appConf.entities.sequencers[formData.sequencer]}
          </span>
        </Typography>
      </Box>
      <Box className={classes.alert}>
        <Alert variant="outlined" severity="warning">
          {t("Sample name must start with 'S'")}
        </Alert>
      </Box>
      {fileMeta.length ? (
        <form onSubmit={handleSubmit}>
          <Table>
            <TableHead>
              <TableRow>
                {headers &&
                  Object.values(headers).map((item, index) => (
                    <TableCell key={index}>{item}</TableCell>
                  ))}
                <TableCell>{t("Pair 1")}</TableCell>
                <TableCell>{t("Pair 2")}</TableCell>
                <TableCell>{t("Role")}</TableCell>
                <TableCell>{t("Variant Frequency Calculation")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fileMeta.map((file, index) => {
                return (
                  <TrioSampleFileTableRow
                    key={index}
                    index={index}
                    headers={headers}
                    sequencerType={formData.sequencer}
                    fileMeta={fileMeta}
                    setFileMeta={setFileMeta}
                    fileNames={fileNames}
                    setFileNames={setFileNames}
                    formError={formError}
                    setFormError={setFormError}
                    roles={roles}
                    setRoles={setRoles}
                  />
                );
              })}
            </TableBody>
          </Table>
          <Box display="flex" mt={5}>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<NavigateBeforeIcon />}
              onClick={() => setPhase("init")}
            >
              {t("Back")}
            </Button>
            <Button
              type="submit"
              style={{ marginLeft: "auto" }}
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
            >
              {t("Submit")}
            </Button>
          </Box>
        </form>
      ) : (
        ""
      )}
    </Grid>
  );
};

const ACNVSampleFileTableRow = (props) => {
  const {
    headers,
    sequencerType,
    index,
    fileMeta,
    setFileMeta,
    formError,
    setFormError,
  } = props;

  const { t } = useTranslation();

  const genders = {
    x: "Female",
    y: "Male",
  };

  const updateFileMeta = (name, value) => {
    let newFileMeta = fileMeta.slice(0);
    newFileMeta[index][name] = value;
    setFileMeta(newFileMeta);
  };

  return (
    <TableRow>
      {Object.keys(headers).map((header, i) => (
        <TableCell key={i}>
          <CustomTextField
            rowIndex={index}
            inputIndex={i}
            sequencerType={sequencerType}
            label={headers[header]}
            name={header}
            value={fileMeta[index][header] ? fileMeta[index][header] : ""}
            updateFileMeta={updateFileMeta}
            formError={formError}
            setFormError={setFormError}
          />
        </TableCell>
      ))}
      <TableCell>
        <CustomDropdown
          options={genders}
          name={"gender"}
          labelTitle={t("Gender")}
          updateFileMeta={updateFileMeta}
          isRequired={false}
        />
      </TableCell>

      <TableCell>
        {fileMeta[index]["filename"] ? fileMeta[index]["filename"] : ""}
      </TableCell>

      <TableCell>
        <FormControlLabel
          onChange={(event, checked) => {
            updateFileMeta(event.target.name, checked);
          }}
          checked={fileMeta[index].calculate_frequency ?? true}
          control={<Checkbox name="calculate_frequency" />}
        />
      </TableCell>
    </TableRow>
  );
};

export const ACNVSampleFilesTable = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [formError, setFormError] = useState([]);
  const { appConf, formData, files, fileMeta, setFileMeta, phase, setPhase } =
    props;

  const [headers, setHeaders] = useState(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    if (fileMeta.length && formError.length !== fileMeta.length) {
      safeLogger(fileMeta);
      for (let i = 0; i < fileMeta.length; i++) {
        formError.push({});
      }
      setFormError(formError.slice(0));
    }
  }, [fileMeta]);

  useEffect(() => {
    if (headers) {
      let tmpFileObjects = [];

      for (let i = 0; i < files.length; i++) {
        let fileObj = {};
        const cellValues = getFileCellValues(files[i].name);
        if (cellValues) {
          Object.keys(headers).map(
            (headItem, index) => (fileObj[headItem] = cellValues[index])
          );
        }
        fileObj["byte_size"] = files[i].size;
        fileObj["filename"] = files[i].name;
        // default values for lane
        fileObj["lane"] = "L01";
        fileObj["calculate_frequency"] = true;

        tmpFileObjects.push(fileObj);
      }

      setFileMeta(tmpFileObjects);
    }
  }, [headers]);

  useEffect(() => {
    if (phase === "sampleMeta") {
      if (!headers) {
        setHeaders({
          sample_name: "Sample Name",
        });
      }
    }
  }, [phase]);

  const isInputFormatValid = () => {
    for (let i = 0; i < formError.length; i++) {
      const formErrorKeys = Object.keys(formError[i]);
      for (let j = 0; j < formErrorKeys.length; j++) {
        if (formError[i][formErrorKeys[j]]) return false;
      }
    }

    return true;
  };

  const isFileConfigUnique = () => {
    const configValues = [];
    for (let i = 0; i < fileMeta.length; i++) {
      const fileConfig = fileMeta[i].sample_name;
      if (!configValues.includes(fileConfig)) {
        configValues.push(fileConfig);
      } else {
        return false;
      }
    }

    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isInputFormatValid()) {
      if (isFileConfigUnique()) {
        setPhase("credit_calculate");
      } else {
        enqueueSnackbar(
          t(
            "form_conf_not_unique_message",
            "Form configuration is not unique. Please check and try again."
          ),
          {
            variant: "error",
          }
        );
      }
    } else {
      enqueueSnackbar(
        t(
          "form_input_not_valid_message",
          "Form input are not valid. Please check and try again."
        ),
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <Grid item xs={12} className={clsx({ [classes.hidden]: phase === "init" })}>
      <Box className={classes.metaSummary}>
        <Typography>
          <span style={{ color: "#5e5e5e" }}>{t("Title")}:</span>
          <span style={{ marginLeft: "4px", marginRight: "20px" }}>
            {formData.name}
          </span>
        </Typography>
        <Typography>
          <span style={{ color: "#5e5e5e" }}>{t("Kit")}:</span>
          <span style={{ marginLeft: "4px", marginRight: "20px" }}>
            {appConf.entities.kits[formData.kit]}
          </span>
        </Typography>
        <Typography>
          <span style={{ color: "#5e5e5e" }}>{t("Sequencer")}:</span>
          <span style={{ marginLeft: "4px", marginRight: "20px" }}>
            {appConf.entities.sequencers[formData.sequencer]}
          </span>
        </Typography>
      </Box>
      {fileMeta.length ? (
        <form onSubmit={handleSubmit}>
          <Table>
            <TableHead>
              <TableRow>
                {headers &&
                  Object.values(headers).map((item, index) => (
                    <TableCell key={index}>{item}</TableCell>
                  ))}
                <TableCell>{t("Gender")}</TableCell>
                <TableCell>{t("File name")}</TableCell>
                <TableCell>{t("Variant Frequency Calculation")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fileMeta.map((file, index) => {
                return (
                  <ACNVSampleFileTableRow
                    key={index}
                    index={index}
                    headers={headers}
                    sequencerType={formData.sequencer}
                    fileMeta={fileMeta}
                    setFileMeta={setFileMeta}
                    formError={formError}
                    setFormError={setFormError}
                  />
                );
              })}
            </TableBody>
          </Table>
          <Box display="flex" mt={5}>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<NavigateBeforeIcon />}
              onClick={() => setPhase("init")}
            >
              {t("Back")}
            </Button>
            <Button
              type="submit"
              style={{ marginLeft: "auto" }}
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
            >
              {t("Submit")}
            </Button>
          </Box>
        </form>
      ) : (
        ""
      )}
    </Grid>
  );
};
